const globalLexicon = {
  urls: {
    logo: [
      'https://www.loyaltysource.com/images/LSLogoEN.png',
      'https://www.loyaltysource.com/images/LSLogoFR.png'
    ]
  },

  // Shared translation global
  labels: {
    contact: ['Contact us', 'Contactez nous'],
    required: ['required', 'requis'],
    remove: ['Remove', 'Retirer'],
    edit: ['Edit', 'Modifier'],
    cancel: ['Cancel', 'Annuler'],
    add: ['Add', 'Ajouter'],
    update: ['Update', 'Mettre à jour'],
    form: {
      required: {
        error: ['Please fill out all required fields correctly', 'Veuillez remplir tous les champs requis correctement'],
      }
    }
  },
  // end shared translation global

  // Home
  home: {
    // Banners
    intro: {
      pragraphBold0: [
        'Winter, but better ',
        'L’hiver, mais mieux ',
      ],
      paragraphNormal0: [
        'Try Kombi’s heated socks and gloves',
        'Essayez les bas et gants chauffants de Kombi',
      ],
      pragraphBold1: [
        'Outerwear that lets you move like an athlete ',
        'Des vêtements d’extérieur qui vous permettent de bouger comme un athlète ',
      ],
      paragraphNormal1: [
        'Under Armour jackets for all weather',
        'À chaque temps sa veste Under Armour',
      ],
      pragraphBold2: [
        'All your favourite recipes ',
        'Toutes vos recettes préférées ',
      ],
      paragraphNormal2: [
        'Now easier with Ninja appliances ',
        'Maintenant plus faciles avec les appareils Ninja ',
      ],
      pragraphBold3: [
        'Shark Beauty tools ',
        'Les outils Shark Beauty ',
      ],
      paragraphNormal3: [
        'For salon haircare at home ',
        'Pour des soins des cheveux comme au salon à domicile ',
      ],
      pragraphBold4: [
        'Every cook deserves Cangshan performance ',
        'Chaque cuisinier mérite la performance Cangshan ',
      ],
      paragraphNormal4: [
        'Western and Japanese style knives for all kitchens ',
        'Couteaux de style occidental et japonais pour toutes les cuisines ',
      ],
    },
    // end banners

    // 1st section
    rewardsMarketplace: {
      title: [
        'Name brand merchandise for corporate gifting & rewards',
        'Produits de marque pour les cadeaux d’entreprise et les récompenses',
      ],
      subTitle: [
        'We source highly desirable merchandise and provide turn-key fulfillment to create a memorable and effortless corporate gifting experience.',
        'Nous nous approvisionnons en marchandises hautement recherchées et fournissons un service clé en main pour créer une expérience mémorable et sans effort en matière de cadeaux d’entreprise.',
      ],
      iconText1: [
        'Over 4,000 Products<br>& 350+ Brands',
        'Plus de 4 000 produits<br>et plus de 350 marques',
      ],
      iconText2: [
        'Personalized Catalogue<br>Curation',
        'Catalogue<br>personnalisé',
      ],
      iconText3: [
        'Catalog Integration<br>& automation',
        'Intégration et automatisation<br>de catalogue',
      ],
      iconText4: [
        'In-house Logistics<br>& Distribution',
        'Logistique<br>et distribution interne',
      ],
      partner: [
        'Your Go-To Gifting Partner',
        'Votre partenaire pour les cadeaux d’entreprise',
      ],
      mainParagraph: [
        'Deliver the best name brand gifts with Loyalty Source as your sourcing and fulfilment partner and provide premium products to your clients’ years of service program, sales incentive program, or employee recognition program.',
        'Avec Loyalty Source comme partenaire d’approvisionnement et d’exécution, offrez les meilleurs cadeaux de marque et fournissez des produits de qualité à vos clients dans le cadre de leur programme d’années de service, d’incitation à la vente ou de reconnaissance des employés.',
      ],
      btnRewards: [
        'EXPLORE REWARDS',
        'DÉCOUVREZ LES RÉCOMPENSES',
      ],
      btnGifting: [
        'EXPLORE CORPORATE GIFTING',
        'DÉCOUVREZ LES CADEAUX D’ENTREPRISE'
      ]
    },
    // end 1st section

    // 2nd section
    uncomplicatedThings: {
      title: ['We Uncomplicate Things', 'Nous simplifions les choses'],
      mainParagraph: [
        'Our in-house team handles everything from procurement, order fulfillment, and distribution logistics, to customer service, returns, guarantees, and insurance claims.',
        'Notre équipe interne s’occupe de tout, depuis l’approvisionnement,l’exécution des commandes et la logistique de distribution jusqu’au service clientèle, aux retours, aux garanties et aux réclamations d’assurance.',
      ],
      listItems: {
        custom: [
          'Custom kitting, personalised packaging, and letter insertion options.',
          'Confection sur mesure, emballage personnalisé et options d’insertion de messages.',
        ],
        personalized: [
          'Personalized reconciliation, accounting, and customer service.',
          'Réconciliation, comptabilité et service clientèle personnalisés.',
        ],
        bestInClass: [
          'Best-in-class delivery response times with hassle free returns and guarantees.',
          'Les meilleurs délais de livraison de leur catégorie, avec des retours et des garanties sans tracas.',
        ],
        dedicated: [
          'Dedicated account management and modern shopping experience.',
          'Gestion de compte personnalisée et expérience d’achat innovante.',
        ],
        seamless: [
          'Seamless and automated catalog integration & product updates.',
          'Intégration transparente et automatisée du catalogue et des mises à jour des produits.',
        ],
        delivery: [
          'Comprehensive last mile logistics and delivery tracking.',
          'Logistique complète du dernier trajet et suivi des livraisons.'
        ]
      },
      linkListItems: {
        linkRewards: [
          'Rewards & Loyalty Program<br>Catalogue Management',
          'Gestion du catalogue du<br>programme de récompenses et de fidélisation',
        ],
        linkDTC: [
          'Direct-to-Consumer (DTC)<br>Logistics & Fulfillment',
          'Logistique et exécution<br>directe au consommateur (DTC)',
        ],
        linkBulk: [
          'Bulk & Closed-Market<br>Wholesale',
          'Vente en gros et<br>à marché fermé',
        ],
      },
    },
    // end 2nd section

    // 3rd section
    rewardsCatalogue: {
      title: ['Our Rewards Catalog', 'Notre catalogue de récompenses'],
      subTitle: [

      ],
      mainParagraph: [
        'Our catalog contains 4000 products from over 350 of today’s top brands. Updated daily, our catalog has the most sought-after consumer goods on the market. Our catalog includes:  ',
        'Notre catalogue contient 4000 produits de plus de 350 grandes marques actuelles. Mis à jour quotidiennement, celui-ci propose des biens de consommation les plus recherchés sur le marché. Notre catalogue comprend : ',
      ],
      listItems: {
        categories: [
          'All categories from kitchen to electronics, fashion, travel, home appliances, decor, and much more',
          'Toutes les catégories, de la cuisine à l’électronique, en passant par la mode, les voyages, l’électroménager, la décoration et bien plus encore. ',
        ],
        pricePoints: [
          'Products at all price points from $5 to $10,000+',
          'Des produits à tous les niveaux de prix, de 5 $ à 10 000 $ et plus.',
        ],
        competitivePricing: [
          'Competitive industry pricing with thousands of products below MSRP',
          'Des prix compétitifs dans l’industrie avec des milliers de produits en dessous du PDSF. ',
        ],
        forEveryone: [
          'Something for everyone, no matter the demographic',
          'Quelque chose pour tout le monde, quel que soit le profil démographique. ',
        ],
      },
      btnViewProducts: ['See All Products', 'Voyez tous les produits'],
      btnViewBrands: ['See All Brands', 'Voyez toutes les marques'],
    },
    // end 3rd section

    // 4th section
    DistributionCenter: {
      title: [
        'Full Service Distribution Center',
        'Centre de distribution de service intégral',
      ],
      subTitle: [
        'Modern warehouse & logistics management',
        'Gestion logistique et d’entrepôt moderne',
      ],
      mainParagraph: [
        'Our Warehouse Management Software (WMS) was developed in-house to meet the rigorous and specific needs of the Premium and Incentives market and ensure your premium gifts are efficiently managed.',
        `Notre logiciel de gestion d’entrepôt (Warehouse Management Software - WMS) a été développé à l’interne pour répondre aux besoins rigoureux et spécifiques du marché des récompenses et des incitations et pour garantir une gestion efficace de vos cadeaux de prestige.`,
      ],
    },
    // end 4th section

    // Stat count up section
    stats: {
      listItems: {
        facilities: {
          num: ['32000', '32000'],
          label: ['Modern Warehouse Facilities', 'Entreposage moderne'],
        },
        perfectShipping: {
          num: ['99', '99'],
          label: ['Perfect Shipping Rate', `Taux d'expédition exceptionnel`],
        },
        inventory: {
          num: ['85', '85'],
          label: ['Inventory<br />In-Stock Ratio', `Ratio<br />d’inventaire disponible`],
        },
        threeDayShipping: {
          num: ['90', '90'],
          label: ['3-Day<br />Shipping Rate', 'Livraison<br />en trois jours'],
        },
      },
    },
    // end stat count up section
  },
  // end home page

  // Services pages
  services: {
    // Old primary page
    legacy: {
      title: ['Services', 'Services'],
      catalog: {
        title: ['Catalog Automation (APIs)', 'Automatisation du catalogue (API)'],
        paragraph1: [
          'With our Catalog Automation (APIs), you can integrate your system with ours to obtain any of the following information in real time, at your convenience:',
          'Grâce à l’automatisation de catalogage (API), vous pouvez intégrer votre système au nôtre afin d’obtenir les informations suivantes en temps réel, à votre convenance:',
        ],
        listItem1: ['Stock status', 'État des stocks'],
        listItem2: ['Product Status', 'État du produit'],
        listItem3: ['Order Status', 'Statut de la commande'],
        listItem4: ['Product Cost', 'Coût du produit'],
        listItem5: ['Tracking Numbers', 'Numéros de suivi'],
        listItem6: [
          'Full Catalog with Categories',
          'Catalogue complet avec catégories',
        ],
        paragraph2: [
          'You can also submit orders anytime you wish, in real time, so long as your system is integrated with ours and all requests are understood through our linked systems.',
          'Vous pouvez également soumettre des commandes à tout moment, en temps réel, à condition que votre système soit intégré au nôtre et que toutes les demandes soient traitées via nos systèmes connectés.',
        ],
      },
      customizable: {
        title: [
          '100% Customizable Labelling & Packaging for All Your Delivery Needs',
          'Etiquetage et emballage 100% personnalisés pour tous vos besoins de livraison',
        ],
        paragraph1: [
          'Our services allow for any kind of customized labels, wrapping and/or package inserts you want to include on your gift offerings. We collaborate with your team to obtain the desired look and result of all specifications on how you want your purchases to be delivered for any personal or corporate special events.',
          "Nos services permettent que tous les types d’étiquettes, d’emballages et / ou de notices d’emballage soient personnalisés afin de les inclure dans vos offres de cadeaux. Nous collaborons avec votre équipe afin d’obtenir l’apparence que vous souhaitez et nous prenons compte des détails indiquant comment vous souhaitez que vos achats soient livrés, pour un événement spécial personnel ou d'entreprise.",
        ],
      },
      intro: {
        title: [
          'The Best Rewards With Industry Best-In-Class Fulfillment',
          'Les meilleures récompenses de l’industrie de gestion des commandes de première classe',
        ],
        paragraph: [
          'We stock our merchandise in-house, with almost 100% of our orders being processed and shipped within two business days.',
          'Nous entreposons nos marchandises à l’interne, et presque 100 % de nos commandes sont traitées et expédiées dans les deux jours ouvrables.',
        ],
      },
      warehouse: {
        title: [
          '3PL Warehousing for All Your Reporting and Analytic Needs',
          "L'entreposage 3PL pour tous vos besoins en matière de rapports et d'analyse",
        ],
        paragraph1: [
          'Our warehousing capabilities allow us to store up to 85% of all our merchandise onsite, with shipping capabilities of up to 2 days for all of our clients. Our services also extend to our delivery system and logistics. With our 3rd party logistics (3PL) warehousing, you can be sure that your package/s will be in our warehouse and handled with the highest care by our staff members.',
          "Nos capacités d'entreposage nous permettent de stocker jusqu'à 85% de toute notre marchandise sur place, avec des capacités d'expédition pouvant aller aussi rapidement que 2 jours pour tous nos clients. Nos services s'étendent également à notre système de livraison et à notre logistique. Avec notre gestion de réalisation des opérations logistiques (3PL), vous pouvez être assurés que votre colis sera dans notre entrepôt et traité avec le plus grand soin par notre personnel.",
        ],
      },
      whiteglove: {
        title: [
          'White-glove Service Provider for All Your Rewards and Incentive Programs',
          'Fournisseur de services de haute gamme pour tous vos programmes de récompenses et de fidélisation.',
        ],
        paragraph1: [
          'Being product experts, Loyalty Source is a full-service reward provider. With seamless integration, industry leading shipping, professional product sourcing and delivery; Loyalty Source provides the best-in-class fulfillment for all company reward programs.',
          "En tant qu'experts en produits, Loyalty Source est un fournisseur de services complets de récompenses. Avec une intégration transparente, une expédition de premier plan dans l’industrie, l’approvisionnement et la livraison de produits professionnels; Loyalty Source fournit le meilleur service de tous les programmes de récompenses sur le marché.",
        ],
      }
    },
    // end old primary page

    // Services > Rewards page
    rewards: {
      title: [
        'Rewards & Loyalty Incentives',
        'Récompenses et incitatifs de fidélisation',
      ],
      subTitle: ['Services', 'Services'],

      // 1st section
      brandsAndRewards: {
        title: [
          'Over 350 Brands & Over 4,000 Rewards',
          'Plus de 350 marques et plus de 4 000 récompenses',
        ],
        subTitle: [
          'Our carefully curated catalog',
          'Notre catalogue soigneusement préparé',
        ],
        mainParagraph: [
          'With distribution rights to over 350 brands and a catalog of over 4,000 carefully curated rewards, Loyalty Source offers the most diverse portfolio of best-selling merchandise and digital rewards from categories such as electronics. fashion, travel, home appliances, decor, and much more.',
          `Avec ses droits de distribution sur plus de 350 marques et un catalogue de plus de 4 000 récompenses soigneusement sélectionnées, Loyalty Source offre une diversité de produits des plus populaires ainsi que des récompenses dans les catégories de l’électronique, la mode, les voyages, les appareils ménagers, la décoration et bien plus.`,
        ],
        icon1: [
          'Integrates directly<br>with your software',
          `Intégration directe<br>à votre logiciel`,
        ],
        icon2: [
          'Thousands of<br>reward options',
          `Des milliers<br>d’offres de récompenses`,
        ],
        icon3: [
          'Custom-branded<br>rewards experience',
          'Achats de récompenses<br>personnalisées',
        ],
        icon4: [
          'Gift-wrapping &<br>customization',
          'Emballage cadeau et<br>personnalisation',
        ],
      },
      // end 1st section

      // 2nd section
      modernMarketplace: {
        title: ['Modern Rewards Marketplace', 'Marché de récompenses moderne'],
        subTitle: [
          'Delivering happiness every day',
          'Nous livrons du bonheur tous les jours',
        ],
        listItems: {
          brands: [
            'Over 350 brands and 4,000 products available',
            'Plus de 350 marques et 4 000 produits disponibles',
          ],
          productImages: [
            'Big, bold product images available in multiple sizes',
            'Grandes images des produits disponibles en plusieurs formats',
          ],
          productDescriptions: [
            'Expertly written product descriptions available in both English and French',
            'Descriptions de produits, disponibles en anglais et en français, rédigées par des professionnels',
          ],
          catalogueUpdates: [
            'Daily automatic catalog updates and catalog curation',
            'Mise à jour automatique et entretien du catalogue quotidiens',
          ],
          bundling: [
            'Kitting, custom product bundling and packaging with insert options',
            `Options de regroupage et d’emballage de produits sur mesure`,
          ],
          easeToUse: [
            'Easy to use account tools and custom search functions',
            `Outils et fonctions de recherche personnalisées faciles à utiliser`,
          ],
        },
      },
      // end 2nd section

      // 3rd section
      seamlessIntegration: {
        title: ['Seamless Integration', 'Intégration transparente'],
        mainParagraph: [
          'We specialize in API integration services to optimize your operations. By seamlessly connecting your systems with leading APIs we enable efficient data exchange and synchronization. Our expertise eliminates manual data entry, automates processes, and ensures real-time accuracy and reliability. With scalable solutions tailored to your needs, we empower your business to collaborate effectively, streamline operations, and achieve peak performance. Our integration options include: ',
          'Nous sommes spécialisés dans les services d’intégration d’API en vue d’optimiser vos opérations. En connectant de façon transparente vos systèmes avec les principales interfaces de programmation, nous permettons un échange et une synchronisation rapides des données. Notre expertise évite la saisie manuelle des données, automatise les processus et garantit une précision ainsi qu’une fiabilité en temps réel. Grâce à des solutions évolutives adaptées à vos besoins, nous permettons à votre entreprise de collaborer efficacement, de rationaliser ses opérations et d’atteindre des performances optimales. Nos options d’intégration comprennent : '
        ],
      },
      // end 3rd section

      // switcher section
      switcher: {
        ctaProducts: ['Products', 'Produits'],
        ctaGiftCards: ['Gift Cards', 'Cartes-cadeau'],
        ctaExperiences: ['Experiences', 'Expériences'],
    
        products: {
          breville: [
            '<strong>Breville</strong><br />2-Slice Smart Toaster',
            '<strong>Breville</strong><br />Grille-pain intelligent 2 tranches',
          ],
          northFace: [
            '<strong>The North Face</strong><br />Mens Venture 2 Jacket',
            '<strong>The North Face</strong><br />Veste Venture 2 Hommes',
          ],
          apple: [
            '<strong>Apple</strong><br />Macbook Pro 15"',
            '<strong>Apple</strong><br />Macbook Pro 15"',
          ],
          taylormade: [
            '<strong>Taylormade</strong><br />M5 460 Driver',
            '<strong>Taylormade</strong><br />M5 Driver 460',
          ],
          amazon: [
            '<strong>Amazon</strong><br />2nd Gen Amazon Echo Smart Speaker',
            "<strong>Amazon</strong><br />2ème génération d'Amazon Echo haut-parleur intelligent",
          ],
        },
    
        giftCards: {
          canadianTire: [
            '<strong>Canadian Tire e-gift card</strong>',
            '<strong>Carte-cadeau électronique Canadian Tire</strong>',
          ],
          cineplex: [
            '<strong>Cineplex e-gift card</strong>',
            '<strong>Carte-cadeau électronique Cineplex</strong>',
          ],
          esso: [
            '<strong>ESSO e-gift card</strong>',
            '<strong>Carte-cadeau électronique ESSO</strong>',
          ],
          sephora: [
            '<strong>Sephora e-gift card</strong>',
            '<strong>Carte-cadeau électronique Sephora</strong>',
          ],
          winners: [
            '<strong>Winners e-gift card</strong>',
            '<strong>Carte-cadeau électronique Winners</strong>',
          ],
        },
    
        experiences: {
          waySpa: [
            '<strong>A day at the spa</strong>',
            '<strong>Une journée au spa</strong>',
          ],
          keg: [
            '<strong>A meal to remember</strong>',
            '<strong>Un repas gastronomique</strong>',
          ],
          starbucks: [
            '<strong>Your favorite coffee spot</strong>',
            '<strong>Votre café préféré</strong>',
          ],
          seadoo: [
            '<strong>Water activities</strong>',
            '<strong>Des activités nautiques</strong>',
          ],
          hilton: [
            '<strong>Hotel getaway</strong>',
            '<strong>Un séjour à l’hôtel</strong>',
          ],
        },
      },
      // end switcher section

      // last section
      loveLoyaltySource: {
        title: ['Brands Love Loyalty Source', 'Nos marques adorent Loyalty Source'],
        subTitle: [
          'From Apple to Zippo and everything in between',
          'Tout d’Apple à Zippo',
        ],
        mainParagraph: [
          'We’ve developed strong first-party relationships with all our brand partners and suppliers over the years. We work hard every day to bring you exclusive new products, adding to and refining our catalog so that your loyalty participants only and always have the best selection at affordable, market-competitive pricing.',
          `Au fil des ans, nous avons développé des relations de confiance avec nos partenaires et fournisseurs de marque. Nous travaillons fermement chaque jour pour vous offrir de nouveaux produits exclusifs, tout en alimentant et en raffinant notre catalogue afin que vos fidèles participants aient accès aux meilleures sélections et ce, à des prix abordables et concurrentiels sur le marché.`,
        ],
        ctaViewAll: ['View All Brands', 'Voir toutes les marques'],
        ctaBrowseRewardsCatalogue: [
          'Browse Rewards Catalog',
          'Parcourez le catalogue des récompenses',
        ],
      },
      // end last section
    },
    // end services > rewards page

    // Services > Fulfillment page
    fulfillment: {
      title: ['Direct-to-Consumer Fulfillment', 'Livraison directe au client'],
      subTitle: ['Services', 'Services'],
      // 1st section
      affordableServices: {
        title: [
          'You Sell It. We’ll Do The Rest.',
          'Vous vendez. Nous nous occupons du reste.',
        ],
        subTitle: [
          `Affordable Warehousing & Fulfillment Services`,
          `Services d’entreposage et d’exécution abordables`,
        ],
        mainParagraph: [
          'Loyalty Source offers affordable Warehousing & Fulfillment services for Small-to-medium-sized Businesses. Streamline your Direct-to-Consumer logistics operations with our cloud-based  3PL direct-to-consumer warehouse management software.',
          'Loyalty Source offre des services d’entreposage et d’exécution abordables pour les petites et moyennes entreprises. Uniformisez vos opérations logistiques grâce à notre logiciel de gestion d’entrepôt 3PL (cloud) direct aux consommateurs.',
        ],
      },
      // end 1st section

      // 2nd section
      experts: {
        title: [
          'We’re Experts at Making Logistics Look Easy',
          'Nous sommes les meilleurs pour rendre la logistique facile pour tous',
        ],
        subTitle: [
          'Seamlessly manage your inventory with one easy-to-use solution.',
          'Gérez votre inventaire en toute transparence grâce à notre méthode facile à utiliser.',
        ],
        mainParagraph: [
          'Every retailer’s operational ecosystem is unique. From the vendors they work with, to the storefronts they manage, right down to the individual service levels they chose to offer. Seamlessly manage your inventory across all point-of-retail locations and online marketplaces with one easy-to-use solution.',
          `Le système opérationnel de chaque détaillant est unique, à commencer par leurs fournisseurs, aux vitrines qu’ils gèrent, jusqu’aux niveaux de service individuels qu’ils ont choisi d’offrir. Vous pouvez gérer facilement votre inventaire et ce, de manière transparente dans tous les points de vente au détail et sur les marchés en ligne.`,
        ],
        icon1: [
          'Store your inventory<br>by bin or skid',
          'Entreposage de votre inventaire<br>par boîte ou par palette',
        ],
        icon2: [
          'Integrates with your<br>eCommerce application',
          `Intégration à votre<br>application eCommerce`,
        ],
        icon3: [
          'Picked, packed and<br>shipped on your behalf',
          'Collecte, emballage, et<br>expédition en votre nom',
        ],
      },
      // end 2nd section

      // 3rd section
      lastMile: {
        title: ['We Do Last Mile Right', 'Une livraison adéquate'],
        subTitle: [
          'From the warehouse to your doorstep',
          `De l’entrepôt à votre porte`,
        ],
        mainParagraph: [
          "We help with last-mile delivery, the final leg of a package’s journey from the warehouse to your doorstep. That’s the part that costs companies the most and has to be done right. Our scalable solution leverages technology, fulfillment, and logistical efficiency to get your products in the hands of your customers.",
          "Nous vous aidons avec la dernière étape de la livraison du colis, de l’entrepôt à votre porte. C’est la partie la plus coûteuse pour les entreprises et elle doit être faite convenablement. Notre solution évolutive s’appuie sur la technologie, la gestion des commandes et l’efficacité logistique afin de s’assurer que les produits de vos clients soient laissés entre bonnes mains.",
        ],
        listItems: {
          icon1: {
            title: [
              'Seamless Software Integration',
              'Intégration de logiciel transparente',
            ],
            paragraph: [
              'Our software works with the software you’re already using and easily adds additional vendors, mission-critical features and holistics reports.',
              'Notre logiciel fonctionne avec celui que vous utilisez actuellement et vous permet d’ajouter aisément des fournisseurs, des fonctions essentielles à votre entreprise ainsi que des rapports holistiques.',
            ],
          },
          icon2: {
            title: [
              'Advanced Analytics & Reports',
              'Rapports et Analyses supérieurs',
            ],
            paragraph: [
              'Save valuable time and money on managing your logistics operations with Up-to-the-minute logistics reports allows you to make informed, real-time, data-driven, business decisions.',
              'Économisez du temps et de l’argent sur la gestion de vos opérations logistiques grâce à des rapports actualisés qui vous permettent de prendre des décisions d’affaires éclairées, en temps réel et fondées sur des données actuelles.',
            ],
          },
          icon3: {
            title: [
              'Real-Time Inventory & Order Handling',
              `Gestion de l’inventaire et des commandes en temps réel`,
            ],
            paragraph: [
              'Manage your inventory across all sales channels in real-time along with any special requirements or customizations, from kitting and bundling to custom packaging and shipping requests.',
              `Gérez votre inventaire en temps réel parmi les canaux de vente en tenant compte des exigences ou instructions spéciales, de l’emballage personnalisé et autres demandes de livraison.`,
            ],
          },
          icon4: {
            title: [
              'Fully Managed Returns and Customer Service',
              `Gestion des retours et service à la clientèle`,
            ],
            paragraph: [
              'Bilingual Canadian-based contact center, multi-channel support, extended business hours, painless returns and reconciliation procedures.',
              `Centre de contact canadien bilingue, soutien de muliples canaux, heures d’ouverture prolongées, retours sans tracas et procédures de réconciliation.`,
            ],
          },
          icon5: {
            title: [
              'Integrated Shipping Processes',
              `Processus de livraison intégré`,
            ],
            paragraph: [
              'Easily connect our solution with many popular eCommerce platforms and marketplaces.',
              'Connectez facilement notre solution à de nombreuses plates-formes de marchés populaires et de eCommerce.',
            ],
          },
        },
      },
      // end 3rd section
    },
    // end services > fulfillment page

    // Services > Gifting page
    gifting: {
      title: ['Corporate Gifting', 'Cadeaux Corporatifs'],
      subTitle: ['Services', 'Services'],
      // 1st section
      giftingExperience: {
        title: [
          'Create the Perfect Corporate Gifting Experience',
          'Comment offrir le cadeau corporatif idéal',
        ],
        subTitle: [
          `We help Canadian companies reward their employees and their customers, building a culture of loyalty that strengthens the bottom line and grows business.`,
          `Nous aidons les entreprises canadiennes à récompenser leurs employés et leurs clients, en développant une culture de fidélisation qui améliore les résultats et fait croître les affaires.`,
        ],
        mainParagraph: [
          'Build deeper relationships with those important to your business – Loyalty Source is here to help with finding the right corporate gifts and incentives for your customers, colleagues, employees, and channel partners. We can assist in:',
          'Loyalty Source est là pour vous aider à trouver les cadeaux d’entreprise et les incitatifs qui conviennent à vos clients, collègues, employés et partenaires de distribution. Nous offrons :',
        ],
        listItems: {
          corporateGifting: [
            'Corporate gifting',
            `Cadeaux d’entreprise`,
          ],
          employeeIncentives: [
            'Employee incentives',
            'Incitatifs pour les employés',
          ],
          serviceRecognition: [
            'Years of service recognition',
            'Reconnaissance des années de service',
          ],
          virtualGifting: [
            'Virtual gifting',
            'Cadeaux virtuels',
          ],
          decorationRequest: [
            'Decoration upon request',
            `Décoration sur mesure`,
          ],
        },
        contact: [
          'Contact Us To Get Started',
          'Contactez-nous',
        ],
      },
      // end 1st section

      // 2nd section
      catalog: {
        subTitle: [
          'We have a catalog of over 4,000 products from more than 350 of today’s most highly desired brands at discounted pricing, delivered directly to your recipients’ doorstep.',
          'Nous disposons d’un catalogue de plus de 4 000 produits de plus de 350 marques parmi les plus populaires du moment, à des prix réduits, et livrés directement à la porte de vos destinataires.',
        ],
        mainParagraph: [
          'Loyalty Source is a market leader in sourcing quality, brand name merchandise to incentive (B2B) channels across Canada. Our team is happy to create a custom curated catalog for all promotional product program gifting needs, and we can work with you to find the right products above and beyond our selection. Decoration services offered upon request.',
          `Loyalty Source est un chef de file sur le marché de l’approvisionnement de produits de marque de qualité pour les programmes de récompenses partout au Canada. Nous sommes heureux de vous proposer un catalogue personnalisé pour tous vos besoins en matière de cadeaux corporatifs et promotionnels, et nous sommes ravis de travailler avec vous afin de découvrir les meilleurs produits, en plus de notre sélection. Nous proposons des services de décoration sur mesure.`,
        ],
        inspiringBrands: [
          'Featured Brands',
          'Marques en vedette',
        ],
        icon1: ['Name brand products and discounted volume pricing','Produits de marque et à des prix de volume réduits'],
        icon2: ['White-glove fulfillment, shipping and logistics management','Des services haut de gamme en matière de livraison et de logistique'],
        icon3: ['Stock holding after a purchase order','Réservation de l’inventaire après une commande d’achat'],
        corporateGiftsCatalogue: ['Browse our Corporate Gifts catalogue', 'Consultez notre catalogue de cadeaux corporatifs'],
      },
      // end 2nd section

      // 3rd section
      showCatalog: {
        paragraph: [
          'Explore our latest catalogs',
          'Jetez un coup d’œil à nos derniers catalogues'
          ],
      },
      // end 3rd section
    },
    // end services > gifting page
  },
  // end services pages

  // Privacy page
  /* 
    Translation has to follow format of
    1. Title of the section will start with subtitle, for example, subtitle1, etc.
    2. Content will start with paragraph, for example, paragraph1, paragraph1a, etc.
    3. List will start with paragraph and has the word list after the number, for example, paragraph1list, paragraph2list, etc.
    4. List item will be inside the object from number 3 above and go in alphabetical order, for example, paragraph1list.a, paragraph1list.b, etc.
    5. The translation will display in the same order that's written in the languageData from top to bottom.
  */
  privacyTitle: ['Privacy Policy', 'Politique de confidentialité'],
  privacy: {
    subtitle1: ['Information Collected', 'Cueillette d’information'],
    subtitle2: [
      'Cookies and Other Technologies',
      'Témoins (cookies) et technologie',
    ],
    subtitle3: [
      'How Information Provided by You Online is Used',
      'Comment les informations fournies en ligne sont utilisées',
    ],
    subtitle4: [
      'How Your Information is Protected',
      'Comment vos renseignements personnels sont protégés',
    ],
  
    paragraph0a: [
      'Loyalty Source (a division of KLF Group Inc.) and its affiliated businesses strongly advocates the responsible enforcement of its members, Clients, and Affiliates privacy. KLF Group Inc. endeavors to maintain an appropriate balance between the rights and expectations of individuals and the reasonable needs of our business. In so doing, we engage all our customers, clients, partners, in the evolving discussion about data privacy, protection, and security.',
      'Loyalty Source (une division du Groupe KLF inc.) et ses entreprises affiliées préconisent l’utilisation responsable des données personnelles de ses membres, clients et associés. KLF Group inc. s’efforce de maintenir un équilibre approprié entre les droits et les attentes des individus et les besoins raisonnables de son entreprise. Ce faisant, nous incluons tous nos clients, clients et partenaires dans une discussion évolutive sur la confidentialité, la protection et la sécurité des données.',
    ],
    paragraph0b: [
      "Because this Website collects certain information about its visitors, we have developed this Policy to inform you of Loyalty Source’s practices concerning the information that we collect and how it is used. To make this Policy easy to find, it is available throughout the website on the footer area at the bottom of every web page, and at every point where identifiable information may be obtained. This Policy does not apply to the treatment of personal or business contact information collected off-line, or to websites found at other locations, including any of Loyalty Source’s affiliated lines of businesses. We encourage you to read this Policy before using our Website. Capitalized terms have the same meaning as in the Website Terms and Conditions.",
      "Comme ce site Web recueille certains renseignements sur ses visiteurs, nous avons élaboré la présente politique pour vous informer des pratiques de Loyalty Source concernant les renseignements que nous recueillons et la façon dont ils sont utilisés. Pour faciliter l’accès à cette politique, elle est disponible sur l’ensemble du site Web, au bas de chaque page Web, ainsi qu’ à chaque endroit où des renseignements identifiables peuvent être obtenus. Cette politique ne s’applique pas au traitement des renseignements personnels ou commerciaux recueillis hors ligne, ni aux sites Web de la compagnie, y compris dans les secteurs d’activité affiliés à Loyalty Source. Nous vous encourageons à lire cette politique avant d’utiliser notre site Web. Les termes en majuscules ont la même signification que dans les conditions générales du site Web.",
    ],
    paragraph0c: [
      'Your use of the Website constitutes your agreement with all the terms of this Policy. If you do not agree with this Privacy Policy in its entirety, do not access or use any portion of this Website.',
      'Votre utilisation du site Web représente votre acceptation de toutes les modalités de la présente politique. Si vous n’êtes pas d’accord avec cette politique de confidentialité dans son intégralité, n’y accédez pas ou n’utilisez aucune partie de ce site Web.',
    ],
  
    paragraph1a: [
      'Loyaltysource.com collects certain information about visitors to its Website, in such cases as where an email is sent to Loyalty Source or one of our online forms is completed. Loyalty Source will use the information provided for administrative and marketing purposes such as fulfilling subscription requests, understanding and analyzing your responses, needs and preferences, and marketing and providing you information and services. The personal information provided can identify Website visitors and their businesses, and may include such items as name, employer name, email address, job title, job function, location of your business.',
      'Loyaltysource.com recueille certains renseignements sur les visiteurs de son site Web, lorsqu’un courriel est envoyé à Loyalty source ou lorsqu’un de nos formulaires en ligne est rempli. Loyalty Source utilisera les renseignements fournis à des fins de marketing ou d’administration, notamment pour répondre aux demandes d’abonnement, pour comprendre et analyser vos réponses, vos besoins et vos préférences, ou pour commercialiser et vous fournir des renseignements et des services. Les renseignements personnels fournis permettent d’identifier les visiteurs du site Web et leurs entreprises, et peuvent comprendre des éléments tels que le nom, le nom de l’employeur, l’adresse électronique, le titre du poste, la fonction et la localisation de votre entreprise.',
    ],
    paragraph1b: [
      "Loyalty Source may also collect or log information that cannot identify you personally including your computer’s Internet Protocol address and your domain name.",
      "Loyalty Source peut également recueillir ou consigner des renseignements qui ne permettent pas de vous identifier personnellement, y compris l’adresse IP de votre ordinateur et votre nom de domaine.",
    ],
    paragraph2a: [
      'A cookie is a small text file that is sent to your browser from a website and stored on your computer’s hard drive. As is standard practice on many Websites, Loyalty Source uses "cookies" to differentiate one user from another and to pass information from page to page during a single user’s website session. They are read only by the server that placed them and cannot execute any code or virus.',
      'Un cookie est un petit fichier texte qui est envoyé à votre navigateur par un site Web et stocké sur le disque dur de votre ordinateur. Étant pratique courante sur de nombreux sites Web, Loyalty Source utilise des cookies pour différencier un utilisateur d’un autre et pour transmettre des informations d’une page à l’autre au cours d’une même session sur le site. Ils ne sont lus que par le serveur qui les a placés et ne peuvent exécuter aucun code ou causer de virus.',
    ],
    paragraph2b: [
      'Cookies also help us understand which parts of our Websites are the most popular, where our visitors are going and how much time they spend there. You can decide if and how your computer will accept a cookie by configuring your preferences or options with most Internet browsers. Website Users who erase or block cookies, however, will not be able to access many areas or features of our Website.',
      'Les cookies nous aident également à comprendre quelles parties de nos sites Web sont les plus populaires, où vont nos visiteurs et combien de temps ils y passent. Vous pouvez décider si et comment votre ordinateur acceptera un cookie en configurant vos préférences ou options avec la plupart des navigateurs Internet. Les utilisateurs du site Web qui effacent ou bloquent les cookies ne pourront cependant pas accéder à certaines zones ou fonctions de notre site Web.',
    ],
  
    paragraph3a: [
      'We may use the information provided through our online forms to facilitate engagement with Loyalty Source and to fulfill subscriptions to the eNewsletter, respond to your request for information about Loyalty Source, respond to comments you have about our Website, or services provided by Loyalty Source. You may be added to our email list (all such uses being subject to laws regulating them).',
      'Nous pouvons utiliser les renseignements fournis dans nos formulaires en ligne pour faciliter les relations avec Loyalty Source, pour répondre aux abonnements du bulletin électronique, répondre à vos demandes de renseignements sur Loyalty Source, et pour répondre à vos commentaires sur notre site Web ou sur les services fournis par Loyalty Source. Vous pouvez également être ajouté à notre liste d’envoi par courriel (toutes ces utilisations étant assujetties aux lois qui les régissent).',
    ],
    paragraph3b: [
      'We may also aggregate user data which is deemed "non-personal" for various system administration purposes and to monitor website usage. The aggregated data measures site visit information such as number of visits, number of pages viewed and other site statistics, and diagnoses of server requirements. This monitoring process enables us to continuously improve our content and ultimately our service.',
      'Nous pouvons également accumuler des données d’utilisateur qui sont considérées comme "non personnelles" à diverses fins d’administration du système et pour surveiller l’utilisation du site Web. Les données recueillies mesurent l’information sur les visites du site, tels le nombre de visites, le nombre de pages consultées et d’autres statistiques du site, ainsi que les diagnostics des besoins du serveur. Ce processus de surveillance nous permet d’améliorer continuellement notre contenu et notre service.',
    ],
    paragraph3c: [
      'Loyalty Source may also disclose personal or business contact information provided to it to comply with legal and regulatory requirements or to ensure compliance with the terms and conditions of this Website.',
      'Loyalty Source peut également divulguer des renseignements personnels ou commerciaux qui lui sont fournis pour se conformer aux exigences légales et réglementaires ou pour assurer le respect des modalités et conditions du présent site Web.',
    ],
    paragraph4a: [
      'Loyalty Source takes physical, electronic and procedural measures to prevent unauthorized intrusion to our Website and the personal and business contact information it collects. We have taken great measures to comply with universally accepted standards in data security online, such as PCI DSS and SHAW 256 encryption security.',
      'Loyalty Source prend des mesures physiques, électroniques et procédurales pour empêcher toute intrusion non autorisée dans notre site Web et les renseignements personnels et commerciaux qu’elle recueille. Nous avons pris d’importantes mesures pour nous conformer aux normes universellement acceptées en matière de sécurité des données en ligne, telles que la sécurité du cryptage PCI DSS et SHAW 256.',
    ],
  },
  // end privacy page

  // Terms & Condition page
  /* 
    Translation has to follow format of
    1. Title of the section will start with subtitle, for example, subtitle1, etc.
    2. Content will start with paragraph, for example, paragraph1, paragraph1a, etc.
    3. List will start with paragraph and has the word list after the number, for example, paragraph1list, paragraph2list, etc.
    4. List item will be inside the object from number 3 above and go in alphabetical order, for example, paragraph1list.a, paragraph1list.b, etc.
    5. The translation will display in the same order that's written in the languageData from top to bottom.
  */
  termsAndConditionTitle: ['Terms & Conditions', 'Termes et Conditions'],
  termsAndCondition: {
    subtitle1: ['Acceptance', 'Acceptation'],
    subtitle2: ['Use', 'Utilisation'],
    subtitle3: ['Rules and Conduct', 'Règles de conduite'],
    subtitle4: ['Intellectual Property', 'Propriété intellectuelle'],
    subtitle5: ['Privacy', 'Vie privée'],
    subtitle6: ['Confidentiality', 'Confidentialité'],
    subtitle7: [
      'Monitoring, Deleting and Disclosure Notice',
      'Avis de surveillance, de suppression et de divulgation',
    ],
    subtitle8: ['Termination', 'Résiliation'],
    subtitle9: ['Disclaimer of Warranties', 'Exonération de garantie'],
    subtitle10: ['Limitation of Liability', 'Limitation de responsabilité'],
    subtitle11: ['Indemnity and Release', 'Indemnisation et quittance'],
    subtitle12: ['International Use', 'Utilisation internationale'],
    subtitle13: ['Jurisdiction', 'Juridiction'],
    subtitle14: ['General', 'Généralités'],
  
    paragraph1a: [
      'Welcome to the Loyalty Source® Website (the "Website") operated by Klf Group. The Website services (collectively, the "Services") are offered by Loyalty Source through Web pages that display the Loyalty Source navigation bar. This Website uses specific terms and conditions that contain legal obligations to which you will be bound by your use of the Website. Please read them carefully. Your access or use of this Website is expressly conditioned upon your acceptance of these Website Terms and Conditions, and any access, or use of any portion of the Website constitutes your agreement to be bound by the Terms and Conditions. If you do not agree with the Website Terms and Conditions in their entirety, do not access, or use any portion of this Website.',
      'Bienvenue sur le site Web de Loyalty Source® (le " site Web ") exploité par Klf Group. Les services du Site Web (collectivement, les " Services ") sont offerts par Loyalty Source à travers des pages Web qui affichent la barre de navigation de Loyalty Source. Ce site Web utilise des termes et conditions spécifiques qui contiennent des obligations légales auxquelles vous serez lié par votre utilisation du site Web. Veuillez les lire attentivement. Votre accès ou l’utilisation que vous faites de ce site Web est catégoriquement conditionnel à votre acceptation des présentes conditions générales du site Web, et tout accès ou utilisation d’une partie du site Web constitue votre consentement à être lié par les conditions générales. Si vous n’êtes pas d’accord avec les modalités et conditions du site Web dans leur intégralité, n’accédez pas à ce site Web et n’en utilisez aucune partie.',
    ],
    paragraph2a: [
      'Except as otherwise stated, or programmatically restricted, you may view, display, download or print ("Use") any page of this Website, subject to the following conditions:',
      'Sauf indication contraire ou restriction de programme, vous pouvez visualiser, afficher, télécharger ou imprimer (" Utilisation ") toute page de ce site Web, sous réserve des conditions suivantes:',
    ],
    paragraph2list: {
      a: [
        'You use the material contained therein (including but not limited to text, graphics, images, data or sound, collectively, "Content") solely for reference purposes in the intent to enter in or pursue a business relationship with Loyalty Source.',
        'Vous utilisez le matériel qu’il contient (y compris, mais sans s’y limiter, le texte, les graphiques, les images, les données ou le son, collectivement, le " Contenu ") uniquement à des fins de référence dans l’intention d’établir ou de poursuivre une relation commerciale avec Loyalty Source.',
      ],
      b: [
        'You do not modify the Content therein in any manner, nor perform, publish or otherwise make public, or create derivative works of the content or any portion thereof without the express written consent;',
        'Vous ne modifiez pas le Contenu qui s’y trouve de quelque manière que ce soit, ni ne l’exécutez, ne le publiez ou ne le rendez public, ni ne créez d’œuvres dérivées de tout ou partie de ce contenu sans le consentement écrit explicite;',
      ],
      c: [
        'You do not remove, obscure or modify any copyright, trademark or other legal notice;',
        'Vous ne supprimez, n’obscurcissez ou ne modifiez aucun droit d’auteur, marque de commerce ou autre avis juridique;',
      ],
      d: [
        'You do not transfer to any person, entity, or computer, server, or other communications medium any Content found on or derived from this Website for your own or any third party’s commercial purpose without the express written consent.',
        'Vous ne transférez à aucune personne, entité, ou ordinateur, serveur, ou autre support de communication tout Contenu trouvé sur ou dérivé de ce site Web pour votre propre usage ou celui d’un tiers à des fins commerciales sans un consentement écrit explicite.',
      ],
    },
    paragraph2b: [
      'No other uses of the Website are permitted without the express written consent of Loyalty Source, and all rights herein not granted expressly granted are reserved.',
      'Aucune autre utilisation du site Web n’est permise sans le consentement écrit de Loyalty Source, et tous les droits qui ne sont pas expressément accordés sont réservés.',
    ],
  
    paragraph3a: [
      'You agree not to use the Website to:',
      'Vous acceptez de ne pas utiliser le site Web pour:',
    ],
    paragraph3list: {
      a: [
        'Post, transmit, link to, or otherwise distribute any information constituting or encouraging conduct that would constitute a criminal offense or give rise to civil liability; a. Monitor the Website and collect information on Website user activity and any Content posted or provided, irrespective of who posted/provided the Content or whether it was posted/provided properly or in violation of these Website Terms and Conditions; and b. Employ a device to cause deletion of Content that is found on any computer when such computer’s browser is opened. Loyalty Source may disclose any information as necessary to satisfy any law, regulation or other governmental or third party request Loyalty Source deems appropriate. Loyalty Source shall have no duty or obligation to challenge the scope or validity of any government or court order to disclose any such information.',
        'Afficher, transmettre, lier ou distribuer de toute autre manière toute information constituant ou encourageant une conduite qui constituerait une infraction criminelle ou donnerait lieu à une responsabilité civile ; a. Surveiller le site Web et recueillir des renseignements sur les activités des utilisateurs du site Web et tout contenu affiché ou fourni, peu importe qui a affiché ou fourni le contenu ou si celui-ci a été affiché ou fourni correctement ou en violation des présentes modalités du site ; et b. Utiliser un dispositif pour causer la suppression du contenu trouvé sur tout ordinateur lorsque le navigateur dudit ordinateur est ouvert.  Loyalty Source peut divulguer toute information nécessaire pour satisfaire à toute loi, réglementation ou autre demande gouvernementale ou tierce partie que Loyalty Source juge appropriée. Loyalty Source n’a ni le devoir ni l’obligation de contester la portée ou la validité d’une ordonnance gouvernementale ou judiciaire de divulguer de tels renseignements.',
      ],
      b: [
        'Use the Website in a manner which is contrary to law, or would serve to restrict, inhibit or have a negative or damaging effect upon Loyalty Source or any other user;',
        'Utiliser le site Web d’une manière qui est contraire à la loi ou qui aurait pour effet de restreindre, d’inhiber ou d’avoir un effet négatif ou nuisible sur Loyalty Source ou tout autre utilisateur;',
      ],
      c: [
        'Use any device, software or routine to interfere or attempt to interfere with the proper working of this Website;',
        'Utiliser tout dispositif, logiciel ou autre usage pour interférer ou tenter d’interférer avec le bon fonctionnement de ce site Web;',
      ],
      d: [
        'Adapt, translate, modify, decompile, disassemble, remove, or reverse engineer any software, applications, programs or devices used in connection with the Website;',
        'Adapter, traduire, modifier, décompiler, désassembler, supprimer ou éplucher tout logiciel, application, programme ou dispositif utilisé en rapport avec le site Web;',
      ],
      e: [
        'Share the content of this website with any company or person that could be perceived as a competitor to Loyalty Source.',
        'Partager le contenu de ce site avec toute société ou personne qui pourrait être perçue comme un concurrent de Loyalty Source.',
      ],
      f: [
        'Use or otherwise exploit this Website for any purposes other than as expressly provided by these Website Terms & Conditions.',
        'Utiliser ou exploiter autrement ce site Web à des fins différentes que celles expressément prévues dans les présentes conditions générales du site Web.',
      ],
    },
  
    paragraph4a: [
      'All rights, titles and interests in this Website and its intellectual property and other proprietary rights in and affecting this Website (all Content therein) including, without limitation, copyright, trademark, patent, proprietary information and trade secrets applicable thereto (collectively, "Intellectual Property") are owned by Loyalty Source or third parties with all rights reserved. Except as expressly provided, nothing herein or within the Website shall be construed as conferring any license under any of the Intellectual Property rights of Loyalty Source or any third party, whether by implication, waiver, estoppel, or otherwise. The Loyalty Source trademarks and service marks include but are not limited to the following:',
      'Tous les droits, titres et intérêts dans ce site Web, sa propriété intellectuelle et autres droits de propriété sur et affectant ce site Web (tout son contenu), y compris, sans limitation, les droits d’auteur, marques de commerce, brevets, informations exclusives et secrets commerciaux applicables (collectivement, la " propriété intellectuelle ") appartiennent à Loyalty Source ou à des tiers, tous droits réservés. Sauf disposition contraire, aucune disposition du site Web ne doit être interprétée comme conférant une licence en vertu des droits de propriété intellectuelle de Loyalty Source ou d’un tiers, que ce soit par implication, renonciation, mesure préventive  ou autre. Les marques de commerce et marques de service de Loyalty Source comprennent, sans toutefois s’y limiter, ce qui suit:',
    ],
    paragraph4b: [
      '"LOYALTY SOURCE" and "LOYALTYSOURCE.COM" are pending or registered trademarks of KLF Group.',
      'LOYALTY SOURCE", "LOYALTY SOURCE", "LOYALTY SOURCE" et "LOYALTYSOURCE.COM" sont des marques déposées de KLF Group.',
    ],
    paragraph5a: [
      'Loyalty Source is committed to protecting the privacy of our customers’ personal information. Any information you input into this Website will not be stored, kept or used by Loyalty Source for any other purpose but to contact you, upon your request.',
      'Loyalty Source s’engage à protéger la confidentialité des renseignements personnels de ses clients. Toute information que vous partagez dans ce site Web ne sera pas stockée, conservée ou utilisée par Loyalty Source à d’autres fins que de vous contacter, à votre demande.',
    ],
    paragraph5b: [
      'We support industry and government initiatives to protect consumer privacy:',
      'Nous appuyons les initiatives de l’industrie et du gouvernement visant à protéger la vie privée des consommateurs:',
    ],
    paragraph5list: {
      a: [
        'support and endorse the Personal Information Protection and Electronic Documents Act',
        'appuyer et endosser la Loi sur la protection des renseignements personnels et les documents électroniques',
      ],
      b: [
        'members of the Canadian Marketing Association',
        'membres de l’Association Canadienne du Marketing',
      ],
    },
    paragraph6a: [
      'Comments, suggestions, ideas or materials sent or transmitted to Loyalty Source by you or on your behalf will be deemed non-confidential information and will become the property of Loyalty Source. Loyalty Source will have no obligation with respect to such submissions and will be free to use (without limitation as to time, media or geography), copy, modify, create derivative works and/or distribute or transfer it to third parties without limitation, for any purpose, commercial or otherwise, without compensation or acknowledgment to you.',
      'Les commentaires, suggestions, idées ou documents envoyés ou transmis à Loyalty Source par vous ou en votre nom seront considérés comme des renseignements non confidentiels et deviendront la propriété de Loyalty Source. Loyalty Source n’aura aucune obligation à l’égard de ces soumissions et sera libre d’utiliser (sans limitation de temps, de média ou de location), de copier, de modifier, de faire des ajustements et/ou de les distribuer ou de les transférer à des tiers sans limitation, à toute fin, commerciale ou autre, sans compensation ou reconnaissance pour vous.',
    ],
    paragraph7a: [
      'Loyalty Source may, from time to time,',
      'Loyalty Source peut, à l’occasion,',
    ],
    paragraph7list: {
      a: [
        'Monitor the Website and collect information on Website user activity and any Content posted or provided, irrespective of who posted/provided the Content or whether it was posted/provided properly or in violation of these Website Terms and Conditions; and',
        'Surveiller le site Web et recueillir des renseignements sur l’activité des utilisateurs du site Web et sur tout contenu affiché ou fourni, peu importe qui l’a affiché ou fourni ou s’il a été affiché ou fourni correctement ou en violation des présentes conditions générales du site Web; et',
      ],
      b: [
        'Employ a device to cause deletion of Content that is found on any computer when such computer’s browser is opened. Loyalty Source may disclose any information as necessary to satisfy any law, regulation or other governmental or third party request Loyalty Source deems appropriate. Loyalty Source shall have no duty or obligation to challenge the scope or validity of any government or court order to disclose any such information.',
        'Employer un dispositif pour supprimer le Contenu qui se trouve sur n’importe quel ordinateur lorsque le navigateur de l’ordinateur est en fonction. Loyalty Source peut divulguer toute information nécessaire pour satisfaire à toute loi, réglementation ou autre demande gouvernementale ou tierce partie que Loyalty Source juge appropriée. Loyalty Source n’a ni le devoir ni l’obligation de contester la portée ou la validité d’une ordonnance gouvernementale ou judiciaire de divulguer de tels renseignements.',
      ],
    },
    paragraph8a: [
      'You acknowledge and agree that Loyalty Source, in its sole and absolute discretion, may, without notice to you, suspend or terminate your use of, or access to, the Website or any portion thereof. You further agree that Loyalty Source will not be liable to you or to any other person as a result of any such suspension or termination. If you are dissatisfied with this Website or any Content, or with any terms, conditions, rules, policies, guidelines, or practices of Loyalty Source in operating the Website, your sole and exclusive remedy is to discontinue using the Website. In the event of such termination, all rights granted for use of content will be removed immediately.',
      'Vous reconnaissez et acceptez que Loyalty Source, à sa seule et absolue discrétion, peut, sans préavis, suspendre ou mettre fin à votre utilisation ou à votre accès au site Web ou à toute partie de celui-ci. Vous convenez en outre que Loyalty Source ne sera pas responsable envers vous ou envers toute autre personne à la suite d’une telle suspension ou résiliation. Si vous n’êtes pas satisfait de ce site Web ou de son contenu, ou des modalités, conditions, règles, politiques, lignes directrices ou pratiques de Loyalty Source dans l’exploitation du site Web, votre seul et unique recours consiste à cesser d’utiliser le site. Dans le cas d’une telle résiliation, tous les droits accordés pour l’utilisation du contenu seront immédiatement supprimés.',
    ],
    paragraph9a: [
      'YOU EXPRESSLY UNDERSTAND AND AGREE THAT THIS WEBSITE AND ITS CONTENT ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS AND THAT LOYALTY SOURCE MAKES NO WARRANTY OR, REPRESENTATION (COLLECTIVELY, "WARRANTIES") OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO the IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT, AND THAT ALL SUCH WARRANTIES ARE HEREBY EXPRESSLY DISCLAIMED. LOYALTY SOURCE MAKES NO WARRANTY THAT THIS WEBSITE, OR ANY CONTENT OR FUNCTION CONTAINED THEREIN WILL BE PROVIDED ON AN UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE/VIRUS-FREE BASIS, THAT DEFECTS WILL BE CORRECTED, OR THAT THE WEBSITE OR THE SERVERS THAT MAKE IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT SUCH CONTENT WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS. LOYALTY SOURCE MAKES NO WARRANTY REGARDING THE USE OR THE RESULTS OF THE USE OF ANY CONTENT OF THIS WEBSITE IN TERMS OF ITS CORRECTNESS, ACCURACY, RELIABILITY OR OTHERWISE.',
      'VOUS COMPRENEZ ET ACCEPTEZ EXPRESSÉMENT QUE CE SITE WEB ET SON CONTENU SONT FOURNIS "TELS QUELS" ET "TELS QUE DISPONIBLES" ET QUE LOYALTY SOURCE NE DONNE AUCUNE GARANTIE OU REPRÉSENTATION (COLLECTIVEMENT, LES "GARANTIES") D’AUCUNE SORTE, CATÉGORIQUE OU IMPLICITE, NOTAMMENT LES GARANTIES IMPLICITES DE QUALITÉ MARCHANDE, D’ADAPTATION À UN USAGE PARTICULIER ET DE NON CONTREFAÇON, ET QUE TOUTES CES GARANTIES SONT EXPRESSÉMENT REJETÉES. LOYALTY SOURCE NE GARANTIT PAS QUE CE SITE WEB, OU TOUT CONTENU OU FONCTION QU’IL CONTIENT, SERA FOURNI DE FAÇON ININTERROMPUE, EN TEMPS OPPORTUN, SÉCURITAIRE OU SANS ERREUR NI VIRUS, QUE LES DÉFAUTS SERONT CORRIGÉS, OU QUE LE SITE WEB OU LES SERVEURS QUI LE RENDENT DISPONIBLE SONT EXEMPTS DE VIRUS OU D’AUTRES ÉLÉMENTS NUISIBLES, OU QUE CE CONTENU RÉPOND À VOS EXIGENCES OU ATTENTES. LOYALTY SOURCE NE DONNE AUCUNE GARANTIE QUANT À L’UTILISATION OU AUX RÉSULTATS DE L’UTILISATION DE TOUT CONTENU DE CE SITE WEB EN TERMES D’EXACTITUDE, DE PRÉCISION, DE FIABILITÉ OU AUTRE.',
    ],
    paragraph10a: [
      'YOU EXPRESSLY UNDERSTAND AND AGREE THAT IN NO EVENT SHALL LOYALTY SOURCE OR ITS CORPORATE AFFILIATES BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING BUT NOT LIMITED TO ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES, OR ANY DAMAGES FOR LOSS OF PROFITS, INTERRUPTION OF BUSINESS, LOSS OF PROGRAMS OR OTHER DATA, OR LOSS OF USE, SAVINGS, GOODWILL OR OTHER INTANGIBLE LOSSES, REGARDLESS OF WHETHER LOYALTY SOURCE HAS BEEN ADVISED OF OR COULD HAVE FORESEEN THE POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF OR IN CONNECTION WITH: (A) THE USE, INABILITY TO USE OR PERFORMANCE OF ANY OF THE WEBSITE, OR (B) ANY OTHER MATTER RELATING TO THE WEBSITE OR ITS CONTENT, REGARDLESS OR WHETHER ANY OF THE FOREGOING IS DETERMINED TO CONSTITUTE A FUNDAMENTAL BREACH OR FAILURE OF ESSENTIAL PURPOSE.',
      'VOUS COMPRENEZ ET ACCEPTEZ EXPRESSÉMENT QU’EN AUCUN CAS, LOYALTY SOURCE OU SES SOCIÉTÉS AFFILIÉES NE SERONT RESPONSABLES DE QUELQUE DOMMAGE QUE CE SOIT, Y COMPRIS, MAIS SANS S’Y LIMITER, TOUT DOMMAGE DIRECT, INDIRECT, ACCESSOIRE, CONSÉCUTIF, SPÉCIAL OU EXEMPLAIRE, OU TOUT DOMMAGE POUR PERTE DE PROFITS, INTERRUPTION DES AFFAIRES, PERTE DE PROGRAMMES OU AUTRES DONNÉES, OU PERTE D’UTILISATION, ÉCONOMIES, BIENVEILLANCE OU AUTRES PERTES INCORPORELLES, QUE LOYALTY SOURCE AIT ÉTÉ OU NON INFORMÉE DE TELS DOMMAGES, DÉCOULANT DE OU EN RAPPORT AVEC : (A) L’UTILISATION, L’INCAPACITÉ D’UTILISER OU LA PERFORMANCE DE TOUT SITE WEB, OU (B) TOUTE AUTRE QUESTION RELATIVE AU SITE WEB OU À SON CONTENU, QU’IL SOIT DÉTERMINÉ OU NON QUE TOUT CE QUI PRÉCÈDE CONSTITUE UNE VIOLATION FONDAMENTALE OU UN ÉCHEC DE L’OBJECTIF INITIAL.',
    ],
    paragraph10b: [
      'Some jurisdictions may not permit the exclusion or limitation of warranties or damages in certain types of agreements and the foregoing exclusions and limitations may not be applicable to you.',
      'Certaines juridictions peuvent ne pas permettre l’exclusion ou la limitation des garanties ou des dommages-intérêts dans certains types de contrats et les exclusions et limitations précédentes peuvent ne pas s’appliquer à vous.',
    ],
    paragraph11a: [
      'You agree to defend, indemnify and hold Loyalty Source, its parent, affiliates, subsidiaries, and third party information providers, and their respective officers, directors, employees, agents and contractors (collectively, "Indemnitees") harmless from any and all liabilities, claims, costs and expenses, including reasonable attorneys’ fees, related to or in connection with:',
      'Vous acceptez de défendre, d’indemniser et de tenir Loyalty Source, sa société mère, ses sociétés affiliées, ses filiales et ses fournisseurs de renseignements tiers, ainsi que leurs dirigeants, administrateurs, employés, agents et entrepreneurs respectifs (collectivement, les " indemnisés ") hors de toute responsabilité, réclamation, coût et dépense, y compris les honoraires raisonnables des avocats, liés ou en rapport avec:',
    ],
    paragraph11list: {
      a: [
        'Any violation of these Website Terms and Conditions;',
        'Toute violation des présentes conditions générales du site Web;',
      ],
      b: [
        'Use of the Website; the Website Content;',
        'Utilisation du site Web; le contenu du site Web;',
      ],
      c: [
        'Unauthorized use of any user ID or password provided to you; or',
        'l’utilisation non autorisée d’un nom d’utilisateur ou d’un mot de passe qui vous a été fourni; ou',
      ],
      d: [
        'Your service upon Loyalty Source a subpoena or other court or governmental order to produce a person or record in connection with a legal or equitable action or matter to which Loyalty Source is not a party.',
        'Votre signification à Loyalty Source d’une assignation à comparaître ou d’une autre ordonnance d’un tribunal ou d’un gouvernement pour produire une personne ou un document relativement à une action en justice ou en équité ou à une affaire à laquelle Loyalty Source n’est pas une partie.',
      ],
    },
    paragraph11b: [
      'You hereby release and forever discharge the Indemnitees in connection with any error, omission, interruption, deletion, defect, or delay in operations or transmission of this Website; and technical, network, telephone, communications, computer hardware or software malfunctions or errors of any kind.',
      'Par les présentes, vous dégagez les indemnisés de toute responsabilité à l’égard de toute erreur, omission, interruption, suppression, défaut ou retard dans l’exploitation ou la transmission de ce site Web, ainsi que de tout dysfonctionnement ou erreur technique, réseau, téléphone, communications, matériel informatique ou logiciel de toute nature.',
    ],
    paragraph12a: [
      'You agree to comply with all local laws and rules including, without limitation, laws governing the Internet, data, email, Intellectual Property and privacy. You agree to comply with all applicable laws regarding the transmission of technical data exported from the country in which you reside and/or work.',
      'Vous acceptez de vous conformer à toutes les lois et règles locales, y compris, sans limitation, les lois régissant l’Internet, les données, le courrier électronique, la propriété intellectuelle et la confidentialité. Vous acceptez de vous conformer à toutes les lois applicables concernant la transmission de données techniques exportées du pays dans lequel vous résidez et/ou travaillez.',
    ],
    paragraph13a: [
      'This Website originates in Canada. These Website Terms and Conditions shall be governed by and construed in accordance with the laws of the Province of Quebec and the federal laws of Canada applicable therein.',
      'Ce site Web provient du Canada. Les présentes conditions d’utilisation du site Web sont régies et interprétées conformément aux lois de la province de Québec et aux lois fédérales du Canada qui s’y appliquent.',
    ],
    paragraph14a: [
      'These Website Terms and Conditions may be updated and amended from time to time by Loyalty Source, with or without notice, and constitute the entire agreement between Loyalty Source and you pertaining to your use of the Website. Failure of Loyalty Source to insist upon or enforce strict performance of any right or provision of these Website Terms and Conditions shall not constitute or be construed as a waiver of any right or provision. If any provision (or part thereof) contained in these Website Terms and Conditions is determined to be void, invalid or otherwise unenforceable pursuant to a court of competent jurisdiction or arbitrator, such determination shall not affect the remaining provisions (or parts thereof) contained herein.',
      'Les présentes conditions générales du site Web peuvent être mises à jour et modifiées de temps à autre par Loyalty Source, avec ou sans préavis, et constituent l’entente intégrale entre Loyalty Source et vous relativement à votre utilisation du site Web. Le défaut de Loyalty Source d’insister sur l’exécution stricte d’un droit ou d’une disposition des présentes conditions générales ne constitue pas une renonciation à un droit ou à une disposition ni ne doit être interprété comme une renonciation à un tel droit ou à une telle disposition. Si une disposition (ou une partie de celle-ci) contenue dans les présentes conditions générales du site Web est jugée nulle, invalide ou autrement non exécutoire par un tribunal compétent ou un arbitre, cette décision n’aura aucune incidence sur les autres dispositions (ou parties de celles-ci) contenues dans les  présentes.',
    ],
  },
  // end terms & condition page

  // Environment page
  environment: {
    title: [
      'Green Planet',
      'Planète verte'
    ],
    h1: [
      'Corporate Sustainability Program',
      'Programme de durabilité corporative',
    ],
    introP1: [
      'Our company is dedicated to its civic responsibility and has instituted ',
      'Notre entreprise est si dévouée à la responsabilité civique qu’elle a mis en place',
    ],
    introP2: [
      'a plan to meet our corporate sustainability objectives.',
      'un plan pour atteindre ses objectifs de développement durable.',
    ],
  
    greenPlanetH2: ['Green Planet', 'Planète verte'],
    greenPlanetP: [
      'Our company is dedicated to doing its part in keeping the planet green. We firmly believe in a principles-based approach to doing business. This means we operate in ways which at a minimum, meet the fundamental responsibilities of a corporate entity in the areas of environment preservation and sustainability.',
      "Notre société est résolue à faire sa part pour garder la planète verte. Nous croyons fermement en une approche commerciale basée sur des principes essentiels. Cela signifie que nous fonctionnons de manière à respecter au minimum les responsabilités fondamentales d'une entité dans les domaines de la préservation de l’environnement et de la durabilité.",
    ],
  
    programH3a: [
      'Our Corporate Sustainability Program ',
      'Notre programme de développement durable ',
    ],
    programH3b: [
      'has three fundamental governing principles:',
      'repose sur trois principes directeurs fondamentaux :',
    ],
  
    programPaperlessTitle: ['Paperless Processes', 'Processus sans papier'],
    programPaperlessP: [
      'We have either eliminated, or considerably reduced our use of paper, packaging and shipping materials across all our divisions. We have also instituted a corporate recycling program with the goal of decreasing our environmental footprint.',
      "Nous avons éliminé ou considérablement réduit notre utilisation de papier, d’emballage et matériel d’expédition dans toutes nos divisions. Nous avons également mis en place un programme de recyclage en entreprise dans le but de réduire notre empreinte environnementale.",
    ],
  
    programGreenTitle: ['Make it Green Again', 'Être écologique'],
    programGreenP: [
      'We have either eliminated, or considerably reduced our use of paper, packaging and shipping materials across all our divisions. We have also instituted a corporate recycling program with the goal of decreasing our environmental footprint.',
      'Chaque année, nous consacrons une journée à la plantation d’arbres, à l’aide aux efforts environnementaux locaux et au bénévolat pour des causes liées à la durabilité.',
    ],
  
    programRecycleTitle: ['Recycle', 'Recycler'],
    programRecycleP: [
      'We have instituted a corporate recycling program with the goal of decreasing our environmental footprint.',
      'Nous avons mis en place un programme de recyclage d’entreprise dans le but de réduire notre empreinte environnementale.'
    ],
  
    earthFriendlyP: [
      'We proudly reuse shipping materials when we can to cut down on waste and help save the planet. By reusing and recycling packaging materials we can save up to thousands of metric tons of carbon dioxide every year.',
      "Nous réutilisons fièrement le matériel d'expédition lorsque nous le pouvons pour réduire les déchets et aider à sauver la planète. En réutilisant et en recyclant les matériaux d'emballage, nous pouvons économiser jusqu'à plusieurs milliers de tonnes de dioxyde de carbone chaque année.",
    ],
    earthFriendlyH4: ['Did you know?', 'Le saviez-vous?'],
    earthFriendlyInnerL: [
      'About one-third of an average dump is made up of packaging material!',
      "Environ un tiers de la décharge moyenne est constitué de matériaux d'emballage!",
    ],
    earthFriendlyInnerR: [
      'Cardboard can be recycled and reused up to 4x before it is discarded.',
      "Le carton peut être recyclé et réutilisé jusqu'à 4x avant d'être jeté.",
    ],
  
    recyclingH5: [
      'How to Dispose or Recycle Cardboard Boxes:',
      'Comment éliminer ou recycler les boîtes en carton :',
    ],
    recyclingListLeftTop: [
      'Gather your cardboard boxes into piles',
      'Rassemblez vos cartons en piles.',
    ],
    recyclingListRightTop: [
      'Remove any paper, plastic or decals that may be on or inside your boxes',
      'Enlevez tout papier, plastique ou décalque qui pourrait être sur ou à l’intérieur de vos boîtes.',
    ],
    recyclingListLeftBottom: [
      'Flatten your cardboard boxes for pick-up',
      'Aplatissez vos boîtes en carton pour le ramassage.',
    ],
    recyclingListRightBottom: [
      'Place your cardboard boxes outside for collection by the division that picks up recycling in your area',
      "Placez vos boîtes en carton à l’extérieur pour qu'elles soient collectées par la division chargée du recyclage dans votre région.",
    ],
  },
  // end environment page

  // User info page
  userInfo: {
    title: [`My Account`, `Mon compte :`],
    accountOwnerLabel: [`Account Owner (Business Name):`, `Titulaire du compte (Nom de la compagnie):`],
    usernameLabel: [`Username:`, `Nom d’utilisateur :`],
    memberSinceLabel: [`Member Since:`, `Membre depuis :`],
    companyNameLabel: [`Company:`, `Société :`],
    companyAddressLabel: [`Company Address:`, `Adresse de l’entreprise :`],
    accountManagerNameLabel: [
      `Account Manager Name:`,
      `Nom du Gestionnaire de Compte:`,
    ],
    accountManagerEmailLabel: [
      `Account Manager E-mail:`,
      `Courriel du Gestionnaire de Compte:`,
    ],
    accountManagerNotAssignedText: [
      `Not assigned`,
      `Non attribué :`,
    ],
    downloadFile: {
      downloadTitle: [`Download:`, `Télécharger`],
      rewardCatalogueBtnLabel: [`Rewards Catalog`, `Catalogue de récompenses`],
      rewardCatalogueBtnDescription: [
        `My Program's Catalog`,
        `Mon catalogue de programme`,
      ],
    },
  },
  // end user info page

  // Forget password page
  forgetPassword: {
    intro: {
      title: ['Forgot Password', 'Mot de passe oublié'],
    },
    main: {
      title: ['Please enter your email address', 'Veuillez entrer votre adresse courriel'],
      username: ['Email', 'E-mail'],
      submitButton: ['Submit', 'Soumettre'],
      submitButtonSuccess: ['Sent', 'Envoyé'],
    },
    success: {
      message: [
        'An email has been sent with password reset instructions, provided your account is registered.',
        'Un e-mail a été envoyé avec des instructions pour réinitialiser votre mot de passe, à condition que votre compte soit enregistré.', 
      ],
    },
    error: {
      message: [
        'Please provide username and verify that you are not a robot.',
        'Veuillez fournir votre nom d’utilisateur et vérifier que vous n’êtes pas un robot.',
      ],
    },
  },
  // end forget password page

  // Reset password page
  resetPassword: {
    intro: {
      title: ['Reset Password', 'Modifier Mot de passe'],
    },
    main: {
      title: ['Please reset password', 'Veuillez réinitialiser votre mot de passe'],
      password: ['Password', 'mot de passe'],
      newPassword: ['new Password', 'nouveau mot de passe'],
      confirmPassword: ['confirm Password', 'confirmer nouveau mot de passe'],
      submitButton: ['Submit', 'Soumettre'],
      submitButtonSuccess: ['Sent', 'Envoyé'],
    },
    success: {
      message: [
        'Success! Your password has been reset. You can now log in with your new password.',
        'Succès! Votre mot de passe a été réinitialisé. Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.', 
      ],
      login: ['Login', 'Connexion',],
    },
    error: {
      message: [
        'Your password must contain at least one number, one special character and one uppercase and lowercase letter, and at least 8 characters. Also please verify that you are not a robot.',
        'Votre mot de passe doit contenir au moins un chiffre, un caractère spécial et une lettre en majuscule et en minuscule, et au moins 8 caractères. Veuillez également vérifier que vous n\'êtes pas un robot.',
      ],
    },
  },
  // end reset password page

  // Contact us page
  contactUs: {
    title: ['Contact Us', 'Contactez-nous'],
    intro: {
      // title: ['Contact Us', 'Contactez-nous'],
      title: ['Get In Touch', 'Communiquer avec nous'],
      subTitle: ['Request information', 'Demander de l’information'],
    },
    contactInfo: {
      title: [
        'Speak to one of our account executives',
        'Parlez à un de nos gestionnaires de comptes',
      ],
      phone: ['Phone Number: ', 'Numéro de téléphone'],
      //   email: ['info@loyaltysource.com: ', 'info@loyaltysource.com'],
      // liveChat: ['Live Chat', 'Live Chat'],
      tollFree: ['Toll Free: ', 'Sans Frais : ']
    },
    form: {
      fname: ['First Name', 'Prénom'],
      lname: ['Last Name', 'Nom'],
      email: ['Email', 'Courriel'],
      phone: ['Phone Number', 'Numéro de téléphone'],
      phoneExt: ['ext', 'poste'],
      company: ['Company', 'Compagnie'],
      website: ['Company Website', 'Site Web de l’entreprise'],
      jobTitle: ['Title', 'Titre de poste'],
      companySize: ['Company Size', 'No. d’employés'],
      submitButton: ['Request an appointment', 'Demander une consultation'],
      successMsg1: [
        'Thank you for contacting us.',
        'Thank you for contacting us.',
      ],
      successMsg2: [
        'One of our account managers will contact you shortly.',
        'One of our account managers will contact you shortly.',
      ],
      errorMsgRegular: [
        'An error occurred. Please try again.',
        'Il y a eu une erreur. Veuillez recommencer.',
      ],
      errorMsgRequired: [
        '* Please fill the required fields (Min. 3 characters) and verify that you are not robot.',
        '* Veuillez remplir les champs requis (3 caractères minimum) et vérifiez que vous n’êtes pas robot.',
      ],
    },
  },
  // end contact us page

  // Error page
  error: {
    title: [
      'Error',
      'Erreur'
    ],
    header: [
      '', 
      ''
    ],
    description: [
      'Something went wrong. Please use the navigation menu to continue browsing our website, and contact us if the problem recurs.', 
      'Un problème inattendu est survenu. Veuillez utiliser le menu de navigation pour poursuivre votre visite, et contactez-nous si le problème persiste.'
    ],
    code: [
      'Error code: ',
      'Code d’erreur : '
    ],
    message: [
      'Error message: ',
      'Message d’erreur : '
    ],
  },
  // end error page

  // Brand page
  brand: {
    pageTitle: ['Brands', 'Marques'],
    title: ['Over 350 Brands', 'Plus de 350 marques'],
    subTitle: [
      "Only the best-selling merchandise from the world’s most iconic brands.",
      "Seulement la marchandise la plus populaire et les marques les plus reconnues au monde.",
    ],
    mainParagraph: [
      'The Loyalty Source Rewards Catalog is packed with only the best-selling merchandise from the world’s most iconic brands. Shop across 20 high-trend  lifestyle categories from apparel to outdoor equipment.  We go out of our way to stock merchandise that surprises and delights ensuring our catalog offering  meets today’s loyalty customer’s discerning tastes.',
      'Le catalogue des récompenses de Loyalty Source ne contient que les articles les plus vendus des marques les plus reconnues au monde. Magasinez dans 20 catégories différentes de style de vie très tendance, des vêtements à l’équipement de plein air. Nous faisons tout ce qui est en notre pouvoir pour entreposer de la marchandise qui étonne et ravit, tout en nous assurant que notre catalogue répond aux goûts avisés de notre clientèle d’aujourd’hui.',
    ],
  },
  // end brand page

  // About us page
  aboutUs: {
    page: {
      title: ['About Us', 'À propos'],
    },
    intro: {
      title: ['About Us', 'À propos'],
      subTitle: [
        'We’re a team of Strategists, Product Specialists, and Logistics Experts',
        'Nous sommes une équipe constituée de stratèges, de spécialistes en produits et d’experts en logistique',
      ],
      
    },
    experts: {
      title: ['We’re experts at', 'Nous sommes des experts en'],
      mainParagraph: [
        `For over 10 years, Loyalty Source has distinguished itself as Canada’s most reliable Reward Provider to the Premium and Incentives industry. Driven by our desire to offer exceptional service with a human touch, we provide premium-branded, personalized products that loyalty program customers truly love!`,
        `Depuis plus de 10 ans, Loyalty Source se distingue comme étant le fournisseur de récompenses le plus fiable au Canada dans l’industrie des primes et incitatifs. Animés par notre désir d’offrir un service exceptionnel et personnalisé, nous offrons des produits de première qualité adorés par les clients des programmes de fidélisation !`,
      ],
      iconRewards: [
        'Rewards Marketplace<br>Sourcing & Management',
        'Approvisionnement et gestion<br>de plateforme de récompenses',
      ],
      iconWhoesale: [
        'Wholesale<br>of Premium Brands',
        'Ventes en gros<br>de marques haut de gamme',
      ],
      iconFulfilment: [
        `Last-mile Fulfillment &<br>Distribution Logistics`,
        `Logistique d’exécution et<br>de distribution finale`,
      ],
    },
    ourValues: {
      title: ['Our Values', 'Nos valeurs'],
      mainParagraph: [
        'Our corporate values have us deeply involved in our community. We actively work to reduce our ecological footprint with our corporate “Green and Lean” program, we make it a point to give to charities we believe in and volunteering for important causes is truly a part of our company DNA. We care about how we affect our community and our planet and always look to make the world a better place in our own little way.',
        'Nos valeurs d’entreprise nous impliquent profondément dans notre communauté. Nous travaillons activement à réduire notre empreinte écologique. Avec notre programme d’entreprise "Vert et allégé", nous avons comme mandat d’offrir notre aide à des organismes caritatifs auxquels nous croyons. Notre bénévolat envers les causes qui nous sont importantes représente l’ADN de notre entreprise. Nous nous soucions de la façon dont nous influençons notre communauté et notre planète et nous cherchons toujours à faire du monde un meilleur endroit.',
      ],
      linkProgram: [
        `Paid Day Employee Volunteer Program`,
        `Programme de bénévolat d’un jour rémunéré`,
      ],
      linkCharities: [
        'Corporate Sponsorships, Charities & Donations',
        'Commandites, oeuvres et dons corporatifs',
      ],
      linkUN: ['Corporate Sustainabilty Program', 'Programme corporatif de développement durable.'],
    },
    ourPeople: {
      title: ['Our People', 'Notre personnel'],
      mainParagraph: [
        `We’ve built our business on the principles that drive customer success and employee happiness. Although we couldn’t do what we do without the support of modern technology, we understand how a great company is built by great people. That’s why we support our employees and invest in their growth - Having a highly motivated and engaged workforce filled with smart and passionate people is part of our mission.`,
        `Nous avons fondé notre entreprise sur des bases qui favorisent le succès de nos clients et le bonheur de nos employés. Bien que nous ne pourrions pas accomplir ce que nous faisons sans le soutien de la technologie moderne, nous comprenons comment une grande entreprise est construite par une équipe exceptionnelle. C’est pourquoi nous soutenons nos employés et investissons dans leur croissance - Avoir un effectif basé sur la motivation et l’engagement composé de gens passionnés et intelligents est une partie intégrante de notre mission.`,
      ],
    },
    ourPromise: {
      title: ['Our Promise', 'Notre promesse'],
      mainParagraph: [
        `We help our Clients increase their customer loyalty with every reward we ship. We strive to deliver happiness and create a feeling of recognition and satisfaction that turns customers into brand loyalists. We promise to help our clients  efficiently implement, improve, and reduce the  cost of operating their reward programs.  We make a point to put our customers first, and this is reflected everywhere from our corporate objectives right down to our service level agreements.`,
        `Nous aidons nos clients à fidéliser leur propre clientèle à chaque fois que nous livrons une récompense. Nous faisons tout pour apporter du bonheur et ainsi créer un sentiment de reconnaissance et de satisfaction qui transforme les clients en fidèles supporteurs de nos marques. Nous nous engageons à aider nos clients à mettre en œuvre, améliorer et réduire efficacement les coûts de fonctionnement de leurs programmes de récompenses. Nos clients représentent notre priorité et celle-ci se reflète partout, depuis nos objectifs d’entreprise jusqu’à notre entente de niveau de service.`,
      ],
      iconFirstContact: [
        `Aim to resolve emails & calls on first contact`,
        `Nous visons à répondre aux appels et aux courriels dès le premier contact`,
      ],
      iconSubsitutionOrders: [
        `Ensure no-hassle returns & minimize substitution orders`,
        `Nous assurons des retours sans tracas et nous minimisons les commandes de remplacement`,
      ],
      iconCSATScores: [
        `Strive for operational excellence & perfect CSAT scores`,
        `Nous visons l’excellence opérationnelle tout en préconisant la satisfaction de la clientèle`,
      ],
    },
    ourHistory: {
      title: ['Our History', 'Notre histoire'],
      mainParagraph: [
        `In 2009, KLF Group developed LoyaltySource.com as a leading Reward Provider to the Premium and Incentives industry.  Today, hundreds of companies across the world count on us to provide our expertise and services to fulfill their rewards and loyalty program needs.`,
        `En 2009, le Groupe KLF a créé LoyaltySource.com, l’un des principaux fournisseurs dans l’industrie des récompenses et incitatifs. Aujourd’hui, des centaines d’entreprises à travers le monde comptent sur nous pour partager notre expertise et nos services et ainsi répondre à leurs besoins en matière de programmes de récompenses et de fidélisation.`,
      ],
    },
  },
  // end about us page

  // Catalog page
  catalog: {
    title: [`Catalog`, `Catalogue`],
    productLabel_1: ['product found', 'produit trouvé'],
    productLabel_other: ['products found', 'produits trouvés'],
    search: [`Results for `, `Résultats pour `],
    noProducts: ['No products found', 'Aucun produit trouvé'],
    filters: {
      sortBy: ['Sort By: ', 'Réorganiser par: '],
      brandAsc: ['Brand (ascending)', 'Marque (croissant)'],
      brandDesc: ['Brand (descending)', 'Marque (décroissant)'],
      nameAsc: ['Name (ascending)', 'Nom (croissant)'],
      nameDesc: ['Name (descending)', 'Nom (décroissant)'],
      priceAsc: ['Price (ascending)', 'Prix (croissant)'],
      priceDesc: ['Price (descending)', 'Prix (décroissant)'],
      msrpAsc: ['MSRP (ascending)', 'PDSF (croissant)'],
      msrpDesc: ['MSRP (descending)', 'PDSF (décroissant)'],
      idDesc: ['Newest', 'Nouveauté'],
    },
    availability: [ `Show me only what's available`, `Montrez-moi seulement ce qui est disponible`, ],
    brand: {
      title: ['Filter by Brand', 'Filtrer par marque'],
      selectPlaceholder: ['Select a brand…', 'Sélectionner une marque…'],
      loading: ['Loading…', 'Chargement…'],
    },
    feature: {
      title: ['Filter by Feature', 'Filtrer par fonction'],
      selectPlaceholder: ['Select a feature…', 'Sélectionner une fonction'],
      loading: ['Loading…', 'Chargement…'],
      confirm: ['Confirm', 'Confirmer'],
      clear: ['Clear', 'Clair'],
    },
    cart: {
      add: ['Add to Cart', 'Ajouter au panier']
    },
    shoppingList: {
      title: ['Show me products in Shopping List', 'Montrez-moi les produits de ma liste d\'achats'],
      selectPlaceholder: ['Select a shopping list…', 'Sélectionner une liste…'],
      loading: ['Loading…', 'Chargement…'],
      noShoppingList: ['No shopping list found, please add one using the Manage my shopping lists on the left', 
                      'Aucune liste de souhaits trouvée, veuillez en ajouter une en utilisant le bouton "Gérer mes listes de souhaits" à gauche'],
      add: ['Add to ', 'Ajouter à '],
      remove: ['Remove from ', 'Retirer de '],
      defaultList: ['list', 'liste'],
      addToList: ['Add to default Shopping List', 'Ajouter à la liste d\'achats par défaut'],
      removeFromList: ['Remove from default Shopping List', 'Supprimer de la liste d\'achats par défaut'],
      addToOtherList: ['Shopping Lists', 'Listes d\'achats'],
    },
    price: {
      title: ['Price Range', 'Fourchette de Prix'],
      filter: ['Filter', 'Filtrer'],
      cost: ['Price', 'Prix'],
      msrp: ['MSRP', 'PDSF'],
      min: ['min.', 'min.'],
      max: ['max.', 'max.'],
      to: ['to', 'à'],
    },
    viewAll: ['View All Categories', 'Voir toutes les catégories'],
  },
  // end catalog page

  // Corporate Gifts page
  corporateGifts: {
    title: [`Corporate Gifts`, `Cadeaux Corporatifs`],
    productLabel_1: ['product found', 'produit trouvé'],
    productLabel_other: ['products found', 'produits trouvés'],
    search: [`Results for `, `Résultats pour `],
    noProducts: ['No products found', 'Aucun produit trouvé'],
    filters: {
      sortBy: ['Sort By: ', 'Réorganiser par: '],
      brandAsc: ['Brand (ascending)', 'Marque (croissant)'],
      brandDesc: ['Brand (descending)', 'Marque (décroissant)'],
      nameAsc: ['Name (ascending)', 'Nom (croissant)'],
      nameDesc: ['Name (descending)', 'Nom (décroissant)'],
      priceAsc: ['Price (ascending)', 'Prix (croissant)'],
      priceDesc: ['Price (descending)', 'Prix (décroissant)'],
      msrpAsc: ['MSRP (ascending)', 'PDSF (croissant)'],
      msrpDesc: ['MSRP (descending)', 'PDSF (décroissant)'],
      idDesc: ['Newest', 'Nouveauté'],
    },
    availability: [ `Show me only what's available`, `Voir seulement ce qui est disponible`, ],
    brand: {
      title: ['Filter by Brand', 'Filtrer par marque'],
      selectPlaceholder: ['Select a brand…', 'Sélectionner une marque…'],
      loading: ['Loading…', 'Chargement…'],
    },
    feature: {
      title: ['Filter by Feature', 'Filtrer par fonction'],
      selectPlaceholder: ['Select a feature…', 'Sélectionner une fonction'],
      loading: ['Loading…', 'Chargement…'],
      confirm: ['Confirm', 'Confirmer'],
      clear: ['Clear', 'Clair'],
    },
    cart: {
      add: ['Add to Cart', 'Ajouter au panier']
    },
    shoppingList: {
      title: ['Show me products in Shopping List', 'Montrez-moi les produits de ma liste d\'achats'],
      selectPlaceholder: ['Select a shopping list…', 'Sélectionner une liste…'],
      loading: ['Loading…', 'Chargement…'],
      noShoppingList: ['No shopping list found, please add one using the Manage my shopping lists on the left', 
                      'Aucune liste de souhaits trouvée, veuillez en ajouter une en utilisant le bouton "Gérer mes listes de souhaits" à gauche'],
      add: ['Add to ', 'Ajouter à '],
      remove: ['Remove from ', 'Retirer de '],
      defaultList: ['list', 'liste'],
      addToList: ['Add to default Shopping List', 'Ajouter à la liste d\'achats par défaut'],
      removeFromList: ['Remove from default Shopping List', 'Supprimer de la liste d\'achats par défaut'],
      addToOtherList: ['Shopping Lists', 'Listes d\'achats'],
    },
    price: {
      title: ['Filter by Price or MSRP', 'Filtrer par Prix ou PDSF'],
      filter: ['Filter', 'Filtrer'],
      clear: ['Clear', 'Clair'],
      cost: ['Price', 'Prix'],
      msrp: ['MSRP', 'PDSF'],
      min: ['min.', 'min.'],
      max: ['max.', 'max.'],
      to: ['to', 'à'],
    },
    viewAll: ['View All Categories', 'Voir toutes les catégories'],
    selectCategory: ['Choose a category', 'Choisissez une catégorie'],
    categoriesTitle: ['Filter by Category', 'Filtrer par catégorie'],
  },

  // End Promo page

  // Logged-in home page
  loggedInHome: {
    new: {
      title: ['New Products', 'Nouveaux produits'],
      button: ['All New Products', 'Tous les nouveaux produits'],
      counterLabel_one: ['new product', 'nouveaux produit'],
      counterLabel_other: ['new products', 'nouveaux produits'],
      // counterLabelSub: ['since your last vist', 'fr- since your last visit'],
      addLinkLabel: ['ADD TO BOOK', 'AJOUTER AU LIVRE'],
    },
    featured: {
      title: ['Featured Products', 'Produits populaires'],
      button: ['All Featured Products', 'Tous les produits populaires'],
      counterLabel_one: ['featured product', 'produits populaire'],
      counterLabel_other: ['featured products', 'produits populaires'],
      addLinkLabel: ['ADD TO BOOK', 'AJOUTER AU LIVRE'],
    },
    hot: {
      title: ["What's Hot!", "Ce qui est tendance!"],
      button: ['All Hot Products', 'Tous les produits en vedette'],
      counterLabel_one: ['hot product', 'produit en vedette'],
      counterLabel_other: ['hot products', 'produits en vedette'],
      addLinkLabel: ['ADD TO BOOK', 'AJOUTER AU LIVRE'],
    },
    general: {
      button: ['All', 'Tous les '],
      counterLabel_one: ['product', 'produit'],
      counterLabel_other: ['products', 'produits'],
      addLinkLabel: ['ADD TO BOOK', 'AJOUTER AU LIVRE'],
      productsLabel: ['Products', 'produits'],
    },
    canadian: {
      title: ['Canadian Products', 'Produits Canadiens'],
      button: ['All Canadian Products', 'Tous les produits Canadiens'],
      counterLabel_one: ['Canadian product', 'produit Canadien'],
      counterLabel_other: ['Canadian products', 'produits Canadiens'],
      addLinkLabel: ['ADD TO BOOK', 'AJOUTER AU LIVRE'],
    },
  },
  // end logged-in home page

  // Login page
  login: {
    title: ['Login', 'Connexion'],
    header: {
      login: ['Client Portal Login', 'Connexion au portail client'],
    },
    main: {
      username: ['E-mail address', 'Adresse de courriel'],
      password: ['Password', 'Mot de passe'],
      token: ['Please enter the 6-character code that you received by email.', 'Veuillez entrer le code à 6 caractères que vous avez reçu par courriel.'],
      firstName: ['First name', 'Prénom'],
      lastName: ['Last name', 'Nom de famille'],
      company: ['Company', 'Société'],
      email: ['E-mail address', 'Adresse de courriel'],
      login: ['Login', 'Identifiant'],
      phone: ['Phone', 'Téléphone'],
      phoneExt: ['Phone ext.', 'Ext. téléphonique'],
      submitButton: ['Log In', 'Connexion'],
      bypassMfa: ['Skip this step for * day(s) on this device. Recommended only for secure, personal devices.', "Ignorez cette étape sur cet appareil pendant * jour(s). Recommandé uniquement pour les appareils personnels sécurisés."],
      acknowledge: [
        // a href links: you can use html tags directly in this.
        `Please acknowledge that you have read and agree to our <a href="../terms-and-conditions" target="_blank" style="color: rgb(236, 28, 36); text-decoration: none;">
        Terms & Conditions</a> as well as content to our <a href="../privacy-policy" target="_blank" style="color: rgb(236, 28, 36); text-decoration: none;">Privacy Policy</a>.
        `,
        `Veuillez reconnaître que vous avez lu et que vous êtes d’accord avec nos  <a href="../terms-and-conditions" target="_blank" style="color: rgb(236, 28, 36); text-decoration: none;">
        Termes et Conditions</a> ainsi qu’avec le contenu de notre <a href="../privacy-policy" target="_blank" style="color: rgb(236, 28, 36); text-decoration: none;">Politique de Confidentialité</a>.`,
      ],
    },
    footer: {
      forgotPassword: ['Forgot my password', 'Mot de passe oublié'],
      requestAccount: ['Request site access', 'Demande d’accès au site'],
    },
    errors: {
      errorMsg: [
        'Login failed. Please try again.',
        'Connexion échouée. Veuillez recommencer.',
      ],
      errorMsgMFA: [
        'An expired or incorrect code was entered. Please try again.',
        'Code expiré ou incorrect -- SVP essayez de nouveau.',
      ],
      errorMsgExpiredPassword: [
        'Your password has expired. For your convenience, we have sent you an email with a link to reset your password.',
        'Votre mot de passe a expiré. Nous vous avons envoyé un courriel contenant un lien pour réinitialiser votre mot de passe.',
      ],
    },
    customerService: {
      paragraph: ['Customer Service: ', 'Service à la clientèle : '],
      tollFree: ['Toll Free: ', 'Sans frais : ']
      // liveChat: ['Live Chat', 'Live Chat'],
    },
  },
  // end login page

  // Order page
  orders: {
    title: {
      open: ['Open orders', 'Commandes ouvertes'],
      completed: ['Completed orders', 'Commandes complétées']
    },
    search_title: {
      open: ['Search open orders', 'Chercher commandes ouvertes'],
      completed: ['Search completed orders', 'Chercher commandes complétées']
    },
    count: {
      postfix_1: ['order found', 'commande trouvée'],
      postfix_other: ['orders found', 'commandes trouvées']
    },
    header: {
      placed: ['PLACED:', 'EFFECTUÉE LE:'],
      shipped: ['SHIPPED:', 'EXPÉDIÉE LE:'],
      cancelled: ['CANCELLED:', 'ANNULÉE LE:'],
      invoiced: ['INVOICED:', 'FACTURÉE LE:'],
      poNumber: ['PO #:', 'PO N°:'],
      lsRef: ['LS #:', 'LS N°:'],
    },
    detail: {
      process: ['Order is being processed', 'La commande est en cours de traitement'],
      shipped: ['Order is shipped from our warehouse', 'La commande est expédiée de notre entrepôt'],
      invoiced: ['Order is invoiced', 'La commande est facturée'],
      cancelled: ['Order is cancelled', 'La commande est annulée'],
    },
    shippingAddress: ['Shipping address:', 'Adresse de livraison:'],
    description: ['Description:', 'Description:'],
    label: {
      by: ['By', 'Par'],
      quantity: ['Quantity:', 'Quantité:'],
      trackingNumber: ['Tracking number', 'Numéro de suivi'],
      view: ['View detail', 'Voir les détails'],
    },
    filters: {
      per_page: ['per page', 'par page'],
      order_number_asc: ['Order number ascending', 'Numéro de commande croissant'],
      order_number_desc: ['Order number descending', 'Numéro de commande décroissant'],
      creation_date_asc: ['Creation date ascending', 'Date de création croissant'],
      creation_date_desc: ['Creation date descending', 'Date de création décroissant'],
      shipped_date_asc: ['Shipped date ascending', 'Date d’envoi croissant'],
      shipped_date_desc: ['Shipped date descending', 'Date d’envoi décroissant'],
      invoiced_date_asc: ['Invoiced date ascending', 'Date de facturation croissant'],
      invoiced_date_desc: ['Invoiced date descending', 'Date de facturation décroissant'],
      canceled_date_asc: ['Canceled date ascending', 'Date d’annulation croissant'],
      canceled_date_desc: ['Canceled date descending', 'Date d’annulation décroissant'],
    },
    no_orders: ['No order found.', 'Aucune commande trouvée.'],
    sort_label: ['Sort by: ', 'Réorganiser par: '],
    page_size_label: ['Amount: ', 'Montant: ']
  },
  // end order page

  // Product page
  product: {
    title: ['Product', 'Produit'],
    description: ['Description', 'Description'],
    imprintingOptions: ['Personalization Options', 'Options de personnalisation'],
    specifications: ['Specifications', 'Spécifications'],
    additionalInfo: ['Additional Info.', 'Info. additionnelle'],
    logInRequired: ['Login required', 'Connexion requise'],
    recommended: ['Recommended Products', 'Produits recommandés'],
    info: {
      brand: ['Brand: ', 'Marque :'],
      warranty: ['Warranty: ', 'Garantie :'],
      weight: ['Shipping Weight: ', 'Poids de livraison :'],
      size: ['Shipping Size: ', 'Format de livraison :'],
      pounds: ['lbs', 'lbs'],
      inches: ['in.', 'po.'],
      itemSize: ['Item Size: ', 'Taille de l’article :'],
      itemWeight: ['Item Weight: ', 'Poids de l’article :'],
      sku: ['LS Product ID: ', 'ID produit LS : '],
      model: ['Model #: ', '# de modèle : '],
      msrp: ['MSRP: ', 'PDSF : '],
      status: ['Status: ', 'Statut : '],
      ourPrice: ['Our Price: ', 'Notre prix : '],
      discount: ['Discount: ', 'Rabais : '],
      addToCart: ['Add to cart', 'Ajouter au panier'],
      zoomCatalog: ['Create Product Presentation', 'Créer une présentation de produit'],
      productPresentation: ['Create a Product Presentation for Sharing', 'Créer une présentation de produit à partager'],
      productPresentationHover: ['Create a PDF document with Product Details for Sharing', 'Créer un document PDF avec les détails du produit à partager'],
      viewZoomCatalog: ['View ZOOMcatalog™ Design', 'Afficher document ZOOMcatalog™'],
      editZoomCatalog: ['Edit ZOOMcatalog™ Design', 'Éditer document ZOOMcatalog™'],
      zoomLoginRequired: ['Login to ZOOMcatalog™ required to use this feature', 'Connexion avec ZOOMcatalog™ requise'],
      createdZoomCatalog: ['Your Presentation Has Been Created ', 'Votre présentation a été créée'],
      addToShoppingList: ['Add to ', 'Ajouter à '],
      removeFromShoppingList: ['Remove from ', 'Retirer de '],
    },
    shipping: {
      title: ['Estimated Shipping', 'Estimation de livraison'],
      averageShipping: ['Average Shipping', 'Prix moyen'],
      disclaimer: [
        'Estimates are calculated assuming Canada Post Expedited Parcel Service with full insurance coverage. Rates may vary for remote locations and are subject to change without notice.',
        'Les estimations sont calculées pour le service Colis accélérés de Postes Canada avec couverture d’assurance complète. Les tarifs peuvent varier pour des destinations éloignées et sont sujets à changement sans préavis.',
      ],
      logInRequired: ['Login required.', 'Connexion requise.'],
    }
  },
  // end product page

  // Provinces
  provinces: {
    canada: {
      0: ['Alberta', 'Alberta'],
      1: ['British Columbia', 'Colombie-Britannique'],
      2: ['Manitoba', 'Manitoba'],
      3: ['New Brunswick', 'Nouveau-Brunswick'],
      4: ['Newfoundland and Labrador', 'Terre-Neuve-et-Labrador'],
      5: ['Nova Scotia', 'Nouvelle-Écosse'],
      6: ['Northwest Territories', 'Territoires du Nord-Ouest'],
      7: ['Nunavut', 'Nunavut'],
      8: ['Ontario', 'Ontario'],
      9: ['Prince Edward Island', 'Île-du-Prince-Édouard'],
      10: ['Quebec', 'Québec'],
      11: ['Saskatchewan', 'Saskatchewan'],
      12: ['Yukon', 'Yukon'],
    }
  },
  // end provinces

  // Footer
  footer: {
    trademark: {
      version: [ '2.41', '2.41' ],
      LoyaltySource: ['Loyalty Source', 'Loyalty Source'],
      desc: [
        ' is a wholly-owned division of KLF Group.',
        ' est une division à 100% de KLF Group.',
      ],
    },
    legal: {
      copyright: ['Copyright  ©', 'Droits d’auteur  ©'],
      klf: ['KLF Group', 'Groupe KLF'],
      rights: ['All rights reserved.', 'Tous droits réservés.'],
    },
    social: {
      title: ['Follow us:', 'Suivez-nous :'],
    },
    // Right side
    companyInfo: {
      name: ['OUR CONTACT INFORMATION', 'NOS INFORMATIONS DE CONTACT'],
      street: ['8010 Devonshire', '8010 Devonshire'],
      city: ['Mount-Royal,', 'Mont-Royal,'],
      province: ['QC', 'QC'],
      postalCode: ['H4P 2K3', 'H4P 2K3'],
      contactUs: ['Tel.:', 'Tél. :'],
      tollFree: ['Toll-free:', 'Sans frais :'],
      openHours: ['9am to 5pm EST', '9h à 17h HE'],
      openDays: ['Monday to Friday', 'Du lundi au vendredi'],
    },
    // Left side
    company: {
      title: ['OUR COMPANY', 'NOTRE ENTREPRISE'],
      aboutUs: ['About Us', 'À propos de nous'],
      privacy: ['Privacy', 'Confidentialité'],
      terms: ['Terms & Conditions', 'Termes et conditions'],
      global: [
        'Corporate Sustainabilty Program',
        'Programme corporatif de développement durable',
      ],
      presses: [
        'Press & Media',
        'Journaux & Médias'
      ],
      accountRequest: [
        'Open a New Client Account',
        'Demande d\'ouverture d\'un compte-client'
      ],
      usPartner: ['Our sister company for your U.S. needs', 'Notre compagnie-soeur pour vos besoins aux États-Unis'],
    },
    // Middle content
    services: {
      title: ['OUR SERVICES', 'NOS SERVICES'],
      rewards: ['Rewards & Loyalty Incentives', 'Récompenses et incitatifs de fidélisation'],
      directToConsumer: [
        'Direct-to-Consumer Fulfillment',
        'Livraison directe au consommateur',
      ],
      gifting: ['Corporate Gifting', 'Cadeaux corporatifs'],
      bulkSales: ['Wholesale', 'Ventes en gros'],
      brands: ['Brands', 'Marques'],
      // services: ['Services', 'Services'],
      catalogue: ['Product Catalog', 'Catalogue de produits'],
      catalogueAutomation: [
        'Catalog Automation (APIs)',
        'Automatisation de catalogue (API)',
      ],
      brandPartners: [
        'For Our Brand Partners',
        'Pour nos partenaires de marque'
      ],
      quickGuide: [
        'Download Quick Guide',
        'Télécharger Guide rapide',
      ],
      detailedInstructions: [
        'Download Detailed Instructions',
        'Télécharger instructions détaillées',
      ],
      partnersAndSuppliers: {
        title: [
          'For Our Brand Partners and Suppliers',
          'Pour nos partenaires et fournisseurs de marque',
        ],
        mainParagraph: [
          'Brand Partners and Suppliers looking to increase their sales volume while also respecting their equity and market positioning turn to LoyaltySource as their trusted partner. Access new sales channels within the events-based giveaway market, such as casino prizes, Fortune 500 client acquisition incentives, corporate promotional events and much more.',
          'Les partenaires de marque ainsi que les fournisseurs cherchant à augmenter leur volume de ventes, leur capital et leur positionnement sur le marché se tournent tous vers Loyalty Source comme allié. Accédez à de nouveaux outils de vente sur le marché des récompenses et d’événements, tels que les prix de casino, les primes d’acquisition de clients Fortune 500, les cérémonies promotionnelles d’entreprise et bien plus encore.',
        ],
        listItems: {
          fullControl: [
            'Full control of your brand image and equity',
            'Gardez la maîtrise de votre image et capital de marque',
          ],
          liquidate: [
            'Liquidate excess inventory',
            'Liquidez les volumes excédentaires',
          ],
          open: [
            'Open new channels and increase sales',
            'Ouvrez de nouveaux réseaux et augmentez vos ventes',
          ],
        },
      },
      rewardNetwork: {
        title: [
          'Ready to Grow Your Sales in Our Reward Network?',
          'Prêt à augmenter vos ventes dans notre réseau de récompenses ?',
        ],
        subTitle: [
          'Book a meeting with one of our Account Managers.',
          'Prenez rendez-vous avec l’un de nos Gestionnaires de Comptes.',
        ],
        cta: ['Book a Meeting Now', 'Réservez un rendez-vous maintenant'],
      },
    },
  },
  // end footer

  // Header
  header: {
    logIn: ['My Account', 'Mon compte'],
    menu: {
      home: ['HOME', 'ACCUEIL'],
      aboutUs: ['OUR STORY', 'À PROPOS'],
      services: ['SERVICES', 'SERVICES'],
      news: ['PRODUCT NEWS', 'NOUVEAUTÉ PRODUITS'],
      blog: ['BLOG', 'BLOG'],
      brands: ['BRANDS', 'MARQUES'],
      rewards: ['REWARDS', 'RÉCOMPENSES'],
      wholesale: ['WHOLESALE', 'VENTE EN GROS'],
      fulfilment: ['FULFILLMENT', 'EXÉCUTION'],
      gifting: ['CORPORATE GIFTING','CADEAUX CORPORATIFS'],
      product: ['PRODUCTS', 'PRODUITS'],
      settings: ['My Info', 'Paramètres'],
      downloadCatalogue: ['Download Full Catalog', 'Télécharger le catalogue complet'],
      shoppingList: ['Manage my shopping lists', 'Gérer mes listes d\'achats'],
      myPresentation: ['My Presentations', 'Mes présentations'],
      openOrders: ['Open Orders', 'Commandes ouvertes'],
      completedOrders: ['Completed Orders', 'Commandes complétées'],
      logOut: ['Log Out', 'Déconnexion'],
      logIn: ['MY ACCOUNT', 'MON COMPTE'],
      contact: ['GET IN TOUCH', 'CONTACTEZ NOUS'],
      corporateGifts: ['CORPORATE GIFTS', 'CADEAUX CORPORATIFS'],
      allProducts: ['ALL PRODUCTS', 'TOUS'],
    },
    popup: {
      text: ['PRODUCTS', 'PRODUITS'],
    },
    search: ['Search', 'Rechercher'],
    customerService: ['Customer Service:', 'Service à la clientèle :'],
    tollFree: ['Toll Free:', 'Sans frais :'],
  },
  // end header

  // Product List
  productList: {
    noProducts: ['No products found', 'Aucun produit trouvé'],
    discontinued: ['DISCONTINUED', 'Discontinué'],
    price: ['Price: ', 'Prix: '],
    msrp: ['MSRP: ', 'PDSF: '],
  },
  // end product list

  // Report page
  report: {
    requestingReport: ['Requesting Report…', 'Demande de rapport en cours...'],
    reports: ['Reports', 'Rapports'],
    requestProductReport: [
      'Request product report',
      'Demander un rapport de produit',
    ],
    requestOrderReport: [
      'Request order report',
      'Demander un rapport de commande',
    ],
    columnDownload: ['Download', 'Télécharger'],
    columnType: ['Type', 'Type'],
    columnID: ['ID', 'ID'],
    columnRequestDate: ['Request Date', 'Date de demande'],
    columnCompletionDate: ['Completion Date', 'Date d’achèvement'],
    columnLanguage: ['Language', 'Langue'],
    buttonPrevious: ['Previous', 'Précédente'],
    buttonNext: ['Next', 'Prochaine'],
    page: ['Page', 'Page'],
    of: ['of', 'de'],
    rows: ['rows', 'rangées'],
  },
  // end report page

  // Checkout page
  checkout: {
    title: ['Checkout', 'Passer à la caisse'],
    confirm: ['Place Order', 'Passer commande'],
    next: ['Shipping Information', "Informations d'expédition"],
    back: ['Modify Order', 'Modifier la commande'],
    continue :['Continue Shopping', 'Continuer vos achats'],
    missing: ['This field is missing', 'Ce champ est vide'],
    list: {
      title: ['My Cart', 'Mon Panier'],
      sku: ['SKU', 'Art.'],
      buttonRemove: ['Remove', 'Retirer'],
      noResult: ['Currently there is no item in the cart. Click on the "Add To Cart" button under the product to add it into the cart.', 'Il n\'y a présentement aucun article dans le panier. Cliquez sur le bouton "Ajouter au panier" sous le produit pour l\'ajouter.'],
      total: ['Total', 'Total'],
      explainText: ['You may adjust the quantity of any product, or even remove it, and the total price will adjust accordingly.', 'Vous pouvez ajuster la quantité de n\'importe quel produit, ou même le supprimer, et le prix total s\'ajustera en conséquence.'],
    },
    summary: {
      title: ['Order Summary', 'Sommaire de la commande'],
      total: ['Total', 'Total'],
    },
    notes: {
      title: ['Notes', 'Notes'],
      label: ['Notes for the order', 'Notes pour la commande'],
    },
    shipping: {
      title: ['Shipping Address', 'Adresse d\'expédition'],
      firstname: ['First name', 'Prénom'],
      lastname: ['Last name', 'Nom'],
      otherName: ['Other name', 'Autre nom'],
      company: ['Company', 'Compagnie'],
      street1: ['Street', 'Rue'],
      street2: ['Street 2', 'Rue 2'],
      city: ['City', 'Ville'],
      province: ['Province/State', 'Province/Etat'],
      selectPlaceholder: ['Select a province…', 'Sélectionner une province…'],
      postalCode: ['Postal code', 'Code postal'],
      phone: ['Phone number', 'Numéro de téléphone'],
      email: ['E-mail address', 'Adresse courriel'],
      address: {
        default: ['Default address', 'Adresse par défaut'],
      },
    },
    info: {
      title: ['Billing Information', 'Informations de facturation'],
      po: ['Order number', 'Numéro de commande'],
      submitBy: ['Submitted by', 'Soumis par'],
      phoneInput: ['Phone number (defaults to your company)', 'Numéro de téléphone (par défaut, celui de votre entreprise)'],
      phone: ['Phone number', 'Numéro de téléphone'],
      email: ['E-mail address', 'Adresse courriel']
    },
    submit: {
      title: ['Purchase Order Confirmation', 'Confirmation de commande'],
      paragraph1a: ['Your Purchase Order ', 'Votre commande '],
      paragraph1b: [
        ' has been confirmed. The confirmation has also been sent to your e-mail address at ',
        ' a été confirmée. Une confirmation a également été envoyée à votre adresse courriel à '
      ],
      paragraph1c: [
        ' within the next 10 minutes depending on your e-mail provider.', 
        ' d\'ici 10 minutes selon votre fournisseur de courriel.'
      ],
      paragraph2: [
        'If you have not received e-mail confirmation after an hour, please contact us as soon as possible to confirm your order.',
        'Si vous n\'avez pas reçu de confirmation par courriel après une heure, veuillez nous contacter dès que possible pour confirmer votre commande.',
      ],
      paragraph3: [
        'If you have any question regarding your order, please contact your account manager directly and we are more than happy to answer.',
        'Si vous avez une question concernant votre commande, veuillez contacter votre gestionnaire de compte directement et nous serons ravis de vous aider.',
      ],
    },
    billingAddress: {
      title: [
        'Billing Address', 'Adresse de facturation'
      ],
      name: [
        'Name', 'Nom'
      ],
      companyName: [
        'Company Name', 'Nom de la compagnie'
      ],
      address: [
        'Address', "Adresse"
      ]
    },
    breadcrumbs: {
      myCart: ['My Cart', 'Mon panier'],
      shippingInfo: ['Shipping Details', 'Informations d\'expédition'],
      confirmation: ['Confirmation', 'Confirmation'],
    },
    email: {
      confirmation: {
        subject: [
          'Loyalty Source Order #{id} Confirmation',
          'Confirmation de votre commande Loyalty Source #{id}',
        ],
        title: ['Loyalty Source', 'Loyalty Source'],
        emailTitle: ['We received your order!', 'Nous avons reçu votre commande!'],
        emailSubtitle: ['Our team is now gathering your items for shipping.', 'Notre équipe est en train de préparer votre commande pour l\'expédition.'],
        greeting: ['Hello', 'Bonjour'],
        paragraph1: [
          `You'll receive an email notification once your order ships along with tracking information. Please note that orders which contain more than one item may ship separately.`,
          `Vous recevrez une notification par courriel lorsque votre commande sera expédiée avec les informations de suivi. Veuillez noter que les commandes de plus d'un produit peuvent être expédiées séparément.`
        ],
        summaryTitle: [
          'Please find a summary of your order below:', 
          'Voici le résumé de votre commande:'
        ],
        viewOrderURL: [
          'https://www.loyaltysource.com/en/orders?completed=false',
          'https://www.loyaltysource.com/fr/orders?completed=false'
        ],
        viewOrderLabel: [ 'View My Orders', 'Voir mes commandes' ],
        orderNumberPrefix: [ 'Order Ref: ', 'Référence de la commande: ', ],
        deliveryTitle: [ 'Delivery Address: ', 'Adresse de livraison: ' ],
        sku: [ 'SKU: ', 'SKU: ' ],
        quantity: [ 'Qty: ', 'Quantité: ' ],
        unitPrice: [ 'Unit Price: ', 'Prix à l\'unité: ' ],
        total: [ 'Total: ', 'Total: ' ],
        signOff: [ 'Take Care!', 'Bien à vous!' ],
        signature: [ 'Loyalty Source Team', 'L\'équipe de Loyalty Source' ]
      }
    },
    disclaimer: [
        'All prices listed do not include taxes and shipping, which will be charged after the order is placed.',
        'Les prix indiqués n\'incluent pas les taxes et les frais d\'expédition, qui seront facturés une fois que la commande sera traitée.'
    ],
  },
  // end checkout page


  //shoppingListContainer
  shoppingListContainer:{
    title: ['All shopping lists', 'Toutes les listes d\'achats'],
    explainText: ['When you click on the heart icon on any of the product image, it will be added to your default shopping list.', 'Lorsque vous cliquez sur l\'icône en forme de cœur sur l\'image d\'un produit, celui-ci sera ajouté à votre liste d\'achats par défaut.'],
    create: ['Create a new list', 'Créer une nouvelle liste'],
    edit: ['Edit name', 'Modifier le nom'],
    default: ['* default list', '* liste par défaut'],
    setDefault: ['Make it the default shopping list', 'En faire ma liste d\'achats par défaut'],
    cancel: ['Cancel', 'Annuller'],
    delete: ['Delete', 'Effacer'],
    save: ['Save', 'Enregistrer'],
    message: ['You have no shopping list yet. Come create one!', "Vous n'avez pas encore de liste de souhaits. Commencez-en une dès maintenant !"],
    defaultName: ['My Shopping List', 'Ma Liste d\'achats'],
    downloadDisclaimer: ['Downloading Excel files may take an extended amount of time. To avoid losing progress on your download, please refrain from refreshing or leaving the products page.', 
                         "Le téléchargement des fichiers Excel peut prendre du temps. Pour éviter d'annuler votre téléchargement, veuillez ne pas rafraîchir ou fermer la page produits."],
    download: ['Download Excel', 'Télécharger le document Excel'],
    cannotDownload: ['{} cannot be downloaded because you have no items in it yet', '{} ne peut pas être téléchargé car il ne contient encore aucun produit'],
    errorMessage: ['Shopping list name should not be empty', 'Le nom de votre liste de souhaits ne peut pas être vide'],
    sideBarTitle: ['Manage my shopping lists', 'Gérer mes listes d\'achats'],
    cannotDelete: ['Cannot delete default shopping list', 'Vous ne pouvez pas effacer votre liste de souhaits par défaut'],
    zoomCatalog: ['Create Product Presentation', 'Créer une présentation de produit'],
    viewZoomCatalog: ['View', 'Afficher'],
    editZoomCatalog: ['Edit', 'Éditer'],
    zoomLoginRequired: ['Login to ZOOMcatalog™ required to use this feature', 'Connexion avec ZOOMcatalog™ requise'],
    createdZoomCatalog: ['Your Presentation Has Been Created ', 'Votre présentation a été créée'],
  },
  //end shoppingListContainer

  //blogContainer
  blogContainer: {
    title: [`News`, `Nouvelles`],
    returnToBlog: ['Back', 'Retour'],
    slogan: ['Keep up to date with Loyalty Source’s Blog', 'Soyez au courant des dernières nouveautés avec le blogue de Loyalty Source'],
    publication: ['Published on:', 'Publié le:'],
    nextPage: ['Next Page', 'Page Suivante'],
    previousPage: ['Previous Page', 'Page Précédente']
  },
  //end blogContainer

  //News Container
  newsContainer: {
    slogan:['What’s new at Loyalty Source', 'Les nouveautés chez Loyalty Source']
  },
  //end News Container

  //Account-Request Page
  accountRequest: {
    intro: {
      title: ['NEW CLIENT ACCOUNT', 'NOUVEAU COMPTE-CLIENT'],
    },
    form: {
      subTitle: ['Please use this form to request opening of a new Client Account with Loyalty Source.\nFields with an asterisk(*) are mandatory.', 'Utilisez ce formulaire pour demander l\'ouverture d\'un nouveau compte-client chez Loyalty Source.\nLes champs avec un astérisque(*) sont obligatoires.'],
      companyName: ['Company Name', 'Nom de l’entreprise'],
      address: ['Company Billing Address', 'Adresse de facturation de l’entreprise'],
      city: ['City', 'Ville'],
      province: ['Province/State', 'Province/État'],
      country: ['Country', 'Pays'],
      postalCode: ['Postal/Zip Code - A1A-1A1/97908', "Code Postal - A1A-1A1"],
      canada: ['Canada', 'Canada'],
      usa: ['USA', 'États-Unis d’Amérique'],
      otherCountry: ['Other Countries', 'Autres Pays'],
      companyWebsite: ['Company Website', 'Site web de l’entreprise'],
      officePhone: ['Office Phone Number (e.g. 5551234567)', 'Numéro de téléphone au bureau (p.ex. : 5551234567)'],
      businessType: ['Business Type', 'Type d’entreprise'],
      distributor: ['Distributor', 'Distributeur'],
      corporation: ['Corporation', 'Société'],
      presidentOwner: ['President (or Owner) Name', 'Nom du président (ou propriétaire)'],
      preferredLanguage: ['Preferred Language', 'Langue de préférence'],
      english: ['English', 'English'],
      french: ['Français', 'Français'],
      keyContactInformation: ['Key Contact Information', 'Information sur le contact clé'],
      keyContactFirstName: ['First Name','Prénom'],
      keyContactLastName: ['Last Name','Nom de famille'],
      keyContactTitle: ['Title', 'Titre'],
      keyContactPhone: ['Phone # ex:5551234567', '#téléphone ex:5551234567'],
      keyContactEmail: ['Email', 'Courriel'],
      accountsPayableInformation: ['Accounts Payable Information', 'Information comptes payables'],
      accountsPayableFirstName: ['First Name','Prénom'],
      accountsPayableLastName: ['Last Name','Nom de famille'],
      accountsPayableTitle: ['Title', 'Titre'],
      accountsPayablePhone: ['Phone # e.g.:5551234567', '#téléphone ex:5551234567'],
      accountsPayableEmail: ['Email', 'Courriel'],
      coordinatorInformation: ['Coordinator Information', 'Information sur le coordonateur'],
      coordinatorFirstName: ['First Name','Prénom'],
      coordinatorLastName: ['Last Name','Nom de famille'],
      coordinatorTitle: ['Title', 'Titre'],
      coordinatorPhone: ['Phone # ex:5551234567', '#téléphone ex:5551234567'],
      coordinatorEmail: ['Email', 'Courriel'],
      purchaserInformation: ['Purchaser Information', 'Information sur l’acheteur'],
      purchaserFirstName: ['First Name','Prénom'],
      purchaserLastName: ['Last Name','Nom de famille'],
      purchaserTitle: ['Title', 'Titre'],
      purchaserPhone: ['Phone # ex:5551234567', '#téléphone ex:5551234567'],
      purchaserEmail: ['Email', 'Courriel'],
      submitButton: ['Submit your form', 'Soumettre votre formulaire'],
      serviceTypes: ['Type of services provided', ' Type de services fournis'],
      rewardsRecognition: [' Rewards or Recognition Program', ' Programme de récompense ou de reconnaissance'],
      yearsService: [' Years of Service', ' Années de service'],
      corporateGifting: [' Corporate Gifting', ' Cadeaux corporatifs'],
      eventsGifting: [' Events Gifting', ' Cadeaux pour événements'],
      safetyTraining: [' Safety or Training Programs', ' Programmes de sécurité ou formation'],
      acknowledge: [
        `Please acknowledge that you have read and agree to our <a href="../terms-and-conditions" target="_blank" style="color: rgb(236, 28, 36); text-decoration: none;">
        Terms & Conditions</a> as well as content to our <a href="../privacy-policy" target="_blank" style="color: rgb(236, 28, 36); text-decoration: none;">Privacy Policy</a>.
        `,
        `Veuillez reconnaître que vous avez lu et que vous êtes d’accord avec nos  <a href="../terms-and-conditions" target="_blank" style="color: rgb(236, 28, 36); text-decoration: none;">
        Termes et Conditions</a> ainsi qu’avec le contenu de notre <a href="../privacy-policy" target="_blank" style="color: rgb(236, 28, 36); text-decoration: none;">Politique de Confidentialité</a>.`,
      ],
      successMsg1: [
        'Thank you for contacting us.',
        'Merci de nous avoir contacté.',
      ],
      successMsg2: [
        'One of our account managers will contact you shortly.',
        'Un de nos gestionnaires de compte vous contactera sous peu.',
      ],
      errorMsgRegular: [
        'An error occurred. Please try again.',
        'Il y a eu une erreur. Veuillez recommencer.',
      ],
      errorMsgRequired: [
        '* Please fill the required fields and verify that you are not a robot.',
        '* Veuillez remplir les champs requis et vérifiez que vous n’êtes pas un robot.',
      ],
    },
    contactInfo: {
      title: [
        'Speak to one of our account executives',
        'Parlez à un de nos gestionnaires de compte',
      ],
      phone: ['Phone Number: ', 'Numéro de téléphone'],
      //   email: ['info@loyaltysource.com: ', 'info@loyaltysource.com'],
      // liveChat: ['Live Chat', 'Live Chat'],
      tollFree: ['Toll Free: ', 'Sans Frais : ']
    },
  },
  //End Account-Request Page

  //Contact us form Thank You page
  thankYou: {
    title: ['Thank you!', 'Merci!'],
    intro: {
      titleMsg1: [
        'Thank you for contacting us.',
        'Merci de nous avoir contacté.',
      ],
      titleMsg2: [
        'One of our account managers will contact you shortly.',
        'Un de nos gestionnaires de compte vous contactera sous peu.',
      ],
    },
  },
  //End Contact us form Thank You page

  //Vendor Agreement page
  vendorAgreement: {
    title: ['Vendor Agreement', 'Entente d’approvisionnement'],
    intro: {
      title: ['Vendor Agreement', 'Entente d’approvisionnement'],
      invalidOrMissingToken: ['Invalid Token', 'Jeton non valide'],
      expiredToken: ['Expired Token. Please contact us to request a new link.', 'Jeton expiré. Contactez-nous pour obtenir un nouveau lien.'],
      validToken: ['Please review and accept this agreement', 'Veuillez examiner et accepter cette entente']
    },
    clauses: {
      subtitle1: ['Product listing:', 'Liste des produits :'],
      subtitle2: ['Purchase Orders:', 'Bons de commande :'],
      subtitle3: ['Shipping:', 'Expédition :'],
      subtitle4: ['Stock:', 'Inventaire :'],
      subtitle5: ['Unfulfilled order:', 'Commande non exécutée :'],
      subtitle6: ['Returns:', 'Retours :'],
      subtitle7: ['Order Cancellations:', 'Annulations de commande :'],
      subtitle8: ['Pricing:', 'Tarification :'],
      subtitle9: ['Invoicing:', 'Facturation :'],
      subtitle10: ['Content updates:', 'Mises à jour du contenu :'],
      subtitle11: ['Customer service:', 'Service Clients :'],
      subtitle12: ['Non-sollicitation and Non-compete:', 'Non-sollicitation and Non-concurrence :'],
      subtitle13: ['Permission to use content:', 'Autorisation d’utiliser le contenu :'],
      subtitle14: ['Confidential Information:', 'Information confidentielle :'],
      subtitle15: ['Term:', 'Terme :'],
      subtitle16: ['Termination and Effects of Termination:', 'Résiliation et effets de la résiliation :'],
      subtitle17: ['Other Agreements:', 'Autres accords :'],
    
      paragraph1list: {
        a: [
          'Using your account credentials, we provide you all the tools and information needed to build your reward catalog. All our content and connectivity tools are available for you to use in accordance with our Permission to Use Content terms described below.',
          'En utilisant vos coordonnées de compte, nous vous fournissons tous les outils et information nécessaires pour construire votre catalogue de récompenses. Tous nos outils de contenu et de connectivité sont disponibles pour que vous les utilisiez conformément à nos conditions d’autorisation telles que décrites ci-dessous.'
        ],
        b: [
          'Loyalty Source can provide you with a full product listing in .csv, .xls, .pdf, XML and API format. Upon initial product load, it is your responsibility to always keep your reward catalog updated with our latest version. We greatly suggest using our Web Services to stay informed of product modifications in real time.',
          'Loyalty Source peut vous fournir une liste complète des produits en format .csv, .xls, .pdf, XML et API. Lors du chargement initial du produit, il est de votre responsabilité de toujours garder votre catalogue de récompenses à jour avec notre plus récente version. Nous vous suggérons fortement d’utiliser nos services Web afin de rester informé des modifications des produits en temps réel.'
        ]
      },
      paragraph2list: {
        a: [
          'We are equipped to receive purchase orders (PO) via our API or in XML and Excel format via Secure File Transfer Protocol (SFTP) or secure emails. PO transferred via email can be sent to the following address: orders@loyaltysource.com.',
          'Nous sommes équipés pour recevoir des bons de commande (PO) via notre API ou en format XML et Excel via Secure File Transfer Protocol (SFTP) ou des courriels sécurisés. Les PO transférés par courriel doivent être envoyés à l’adresse suivante : orders@loyaltysource.com.',
        ],
        b: [
          'Please let our integration team know your preferred integration format and we will provide you with the proper integration guidelines.',
          'Veuillez informer notre équipe d’intégration du format souhaité et nous vous fournirons les directives d’intégration appropriées.',
        ],
        c: [
          'If you cannot integrate with our API, this may result in slower fulfilment and response times as well as lack of data reporting.',
          'Si vous ne pouvez pas vous intégrer à notre API, cela peut entraîner des délais d’exécution, des réponses plus lentes ainsi que l’absence de déclaration de données.',
        ],
      },
      paragraph3list: {
        a: [
          'Our warehouse is located in Montreal, Quebec. Every order, unless otherwise specified, ships from this location.',
          'Notre entrepôt est situé à Montréal, Québec. Chaque commande, sauf indication contraire, est expédiée à partir de cet endroit.',
        ],
        b: [
          'We provide you with average shipping rates per province to help budget your full cost based on the location of your clients. We also provide you with an average Canadian shipping rate that can be adjusted based on the mass locations of your client base. Please keep in mind that these are estimated shipping rates calculated using major metropolitan areas and can differ from the actual shipping rate on your invoice.',
          'Nous vous fournissons des tarifs d’expédition habituels par province pour vous aider à planifier votre coût total en fonction de l’emplacement de vos clients. Nous vous fournissons également un tarif d’expédition canadien habituel qui peut être ajusté en fonction de l’emplacement de masse de votre clientèle. Veuillez garder à l’esprit qu’il s’agit de tarifs d’expédition estimés calculés en utilisant les principales régions métropolitaines et peuvent différer du tarif d’expédition réel sur votre facture.',
        ],
        c: [
          'Certain heavier, oversized or more expensive items may require a white glove shipping service. The shipping rates for these items will be provided on a case per case basis upon PO reception or written request. Shipping times for certain of these volumetric items may vary.',
          'Certains articles plus lourds, plus volumineux ou plus chers peuvent nécessiter un service d’expédition de « gants blancs ». Les tarifs d’expédition pour ces articles seront fournis au cas par cas à la réception du bon de commande ou sur demande écrite. Les délais de livraison pour certains de ces articles de grand volume peuvent varier.',
        ],
        d: [
          'We are equipped to drop ship our merchandise on your behalf and offer the following services at no additional charge: \n\t-Packaging; \n\t-Standard Labelling;',
          'Nous sommes équipés pour livrer nos marchandises en votre nom et offrir les services suivants sans frais supplémentaires : \n\t-Emballage;\n\t-Étiquetage standard;',
        ],
        e: [
          'We also offer the following services for an additional fee:\n\t-Gift wrapping;\n\t-Custom packaging;\n\t-Custom insertions;\n\t-Custom labelling;\n\t-Warehousing of your inventory;\n\t-Kitting and light customizations.\nFor a full list of our logistics services, please enquire with your account representative.',
          'Nous proposons également les services suivants moyennant des frais supplémentaires : '+
          '\n\t-Emballage cadeau;' +
          '\n\t-Emballage personnalisé;' +
          '\n\t-Insertions personnalisées;' +
          '\n\t-Étiquetage personnalisé;' +
          '\n\t-Entreposage de votre inventaire;' +
          '\n\t-Préparation de trousses et personnalisations légères.' +
          '\nPour une liste complète de nos services logistiques, veuillez-vous renseigner auprès de votre représentant de compte.',
        ],
        f: [
          'We offer discounted shipping rates via our transporter volume accounts. Every order is carefully packaged and shipped with full insurance coverage and with tracking numbers. In case of merchandise being lost or damaged in transit, we take full responsibility and take all arrangements to mend the situation internally if claim is made within 30 days of initial order shipped date.',
          'Nous proposons des tarifs d’expédition réduits via nos comptes de volume de transport. Chaque commande est soigneusement emballée et expédiée avec une couverture d’assurance complète et des numéros de suivi. En cas de perte ou d’endommagement de la marchandise pendant le transport, nous assumons l’entière responsabilité et prenons toutes les dispositions nécessaires pour remédier à la situation à l’interne si la réclamation est faite dans les 30 jours suivant la date d’expédition initiale de la commande.',
        ],
        g: [
          'At the end of each business day, we can provide you with an automated shipping confirmation file containing every product shipped on that day with tracking number.',
          'À la fin de chaque jour ouvrable, nous pouvons vous fournir un fichier de confirmation d’expédition automatisé contenant chaque produit expédié en ce jour avec numéro de suivi.',
        ],
        h: [
          'If you require us to use your shipping account, we will add a 5.00$ handling fee per item to your invoice. Also note that in case of damage, delay or loss in transit, Loyalty Source cannot assist with the claim process.',
          'Si vous nous demandez d’utiliser votre propre compte d’expédition, nous ajouterons des frais de traitement de 5,00 $ par article à votre facture. Notez également qu’en cas de dommage, de retard ou de perte pendant le transport, Loyalty Source ne peut pas vous aider dans le processus de réclamation.',
        ],
      },
      paragraph4list: {
        a: [
          'We strongly suggest you provide us with a monthly forecast for each item you project purchasing with us. Using this forecast, we determine the proper amount of stock to hold in-house to ensure order fulfilment within an average of 48 hours of order reception.',
          'Nous vous suggérons fortement de nous fournir une prévision mensuelle pour chaque article que vous projetez d’acheter avec nous. À l’aide de ces prévisions, nous déterminons la quantité appropriée d’inventaire à conserver à l’interne pour garantir l’exécution des commandes dans un délai moyen de 48 heures suivant la réception de la commande.',
        ],
        b: [
          'If you cannot provide us with a forecast, shipping times will vary from 2 to 10 business and increase during peak period of the year.',
          'Si vous ne pouvez pas nous fournir de prévision, les délais de livraison varieront de 2 à 10 jours ouvrables et augmenteront pendant la période de pointe de l’année.',
        ],
        c: [
          'We ask that you provide us with the life cycle of each of your programs to avoid overstocking. A 30-day written notice prior to any product being discontinued from your purchasing cycle is required.',
          'Nous vous demandons de nous fournir le calendrier de chacun de vos programmes pour éviter les surplus d’inventaire. Un avis écrit de 30 jours avant que tout produit ne soit interrompu de votre cycle d’achat est requis.',
        ],
      },
      paragraph5list: {
        a: [
          'If an item is discontinued or back-ordered, and you have sent us a forecast for the item, we will notify you in advance via email with a suggested substitute item for you to update your offer.',
          'Si un article est discontinué ou en rupture de stock, et que vous nous avez envoyé une prévision pour l’article, nous vous en informerons à l’avance par courriel avec un article de remplacement suggéré pour vous permettre de mettre à jour votre offre.',
        ],
        b: [
          'If no forecast for the item was provided, we will notify you via email that your PO cannot be fulfilled as such, however, we will always propose a substitute item in order to fulfil the order. All substitutions proposed are of equal or greater value and offered within the same price point. All substitutions require your written approval prior to being shipped.',
          'Si aucune prévision pour l’article n’a été fournie, nous vous informerons par courriel que votre bon de commande ne peut pas être exécuté, cependant, nous proposerons toujours un article de remplacement afin de traiter la commande. Toutes les substitutions proposées sont de valeur égale ou supérieure et proposées dans la même fourchette de prix. Toutes les substitutions nécessitent votre approbation écrite avant d’être expédiées.',
        ],
      },
      paragraph6list: {
        a: [
          'If a shipment is returned to our warehouse as undeliverable by our transporter or unclaimed by the client (hereafter RTS), you will be notified within 2 business days. As of the notification, you have 30 days to contact us with your desired action: send back to customer using same address, send back to customer using a revised address or cancel the order. After 30 days without response to the notification, the order will automatically be cancelled. All cancelled RTS orders are subject to a 15% of order value restocking fee as well as all associated shipping and handling fees, if any.',
          'Si un envoi est retourné à notre entrepôt comme non livrable par notre transporteur ou non réclamé par le client (ci-après RTS), vous en serez informé dans les 2 jours ouvrables. À compter de la notification, vous avez 30 jours pour nous contacter avec l’action souhaitée: retourner au client en utilisant la même adresse, retourner au client en utilisant une adresse révisée ou annuler la commande. Après 30 jours sans réponse à la notification, la commande sera automatiquement annulée. Toutes les commandes retournées annulées sont soumises à des frais de restockage de 15% de la valeur de la commande ainsi qu’à tous les frais d’expédition et de manutention associés, le cas échéant.',
        ],
        b: [
          'RTS order shipping fees, as well as reshipment fees, will be added to the invoice under the same PO number.',
          'Les frais d’expédition de commande RTS, ainsi que les frais de réexpédition, seront ajoutés à la facture sous le même numéro de bon de commande.',
        ],
        c: [
          'All client-requested product returns necessitate a Return Authorization Number (RA) issued by Loyalty Source. The RA number is valid for a 30-day period; after which it will automatically be voided.',
          'Tous les retours de produits demandés par le client nécessitent un numéro d’autorisation de retour (RA) émis par Loyalty Source. Le numéro RA est valide pour une période de 30 jours; après quoi il sera automatiquement annulé.',
        ],
        d: [
          'Clients have 3 days after reception of their order to request a return for a product they no longer want (hereafter Buyer’s Remorse). In order for Loyalty Source to take back the item, it must be unused, unopened, in its original packaging and in resalable condition. If a product is returned to us and does not meet these requirements a refund may not be accorded. If Loyalty Source is notified of a Buyer’s Remorse past the 3-day grace period, we may accept the return with a 15% of product value restocking fee. Upon acceptance of a Buyer’s Remorse, Loyalty Source will provide you with a Return Shipping Label for your client to use. Once we receive the product and it has passed return criteria, Loyalty Source will credit you for the product value minus all shipping and restocking fees, if any.',
          'Le Client dispose de 3 jours après réception de sa commande pour demander le retour d’un produit dont il ne souhaite plus (ci-après le remords de l’acheteur). Pour que Loyalty Source reprenne l’article, il doit être inutilisé, non ouvert, dans son emballage d’origine et en état de revente. Si un produit nous est retourné et ne répond pas à ces exigences, un remboursement peut ne pas être accordé. Si Loyalty Source est informé du remords de l’acheteur après la période de grâce de 3 jours, nous pouvons accepter le retour avec des frais de restockage de 15% de la valeur du produit. Lors de l’acceptation du remords de l’acheteur, Loyalty Source vous fournira une étiquette d’expédition de retour que votre client pourra utiliser. Une fois que nous recevons le produit et qu’il a satisfait aux critères de retour, Loyalty Source vous créditera la valeur du produit moins tous les frais d’expédition et de réapprovisionnement, le cas échéant.',
        ],
        e: [
          'All items damaged in transport must be reported to Loyalty Source within 3 days of order reception by the client. In this event, Loyalty Source will open a claim with the transporter, which may require the cooperation from the client. Once the claim is approved, we will ship out a replacement unit to the client free of charge. Only if the product is no longer available will a refund be offered. In certain cases, we will require the damaged unit be shipped back to us at our cost or an image be provided for insurance claiming purposes.',
          'Tous les articles endommagés en transport doivent être signalés à Loyalty Source dans les 30 jours suivant la réception de la commande par le client. Dans ce cas, Loyalty Source ouvrira une réclamation auprès du transporteur, ce qui nécessite la coopération du client. Une fois la réclamation approuvée, nous expédierons gratuitement une unité de remplacement au client. Un remboursement ne sera offert que si le produit n’est plus disponible. Dans certains cas, nous exigerons que l’unité endommagée nous soit renvoyée à nos frais ou qu’une image soit fournie à des fins de réclamation d’assurance.',
        ],
        f: [
          'Defective products may be sent back to Loyalty Source in order to facilitate the warranty claim. In order to do so, a written notice must be sent within 30 days of reception date, the defect must not be the result of abusive usage and all original parts must be included in the return. Upon reception of the damaged goods, our staff will inspect and test them to ensure the validity of the claim. If we find the product not to be working properly and we cannot initiate the warranty claim; the client will be responsible for the reshipping of the product. If the product is confirmed to be defective, a replacement product will be sent to client free of charge. Only if the product is no longer available will a refund be issued.',
          'Les produits défectueux peuvent être renvoyés à Loyalty Source afin de faciliter la demande de garantie. Pour ce faire, un avis écrit doit être envoyé dans les 30 jours suivant la date de réception, le défaut ne doit pas être le résultat d’une utilisation abusive et toutes les pièces d’origine doivent être incluses dans le retour. Dès réception des marchandises endommagées, notre personnel les inspectera et les testera pour s’assurer de la validité de la réclamation. Si nous constatons que le produit ne fonctionne pas correctement et que nous ne pouvons pas lancer la réclamation au titre de la garantie; le client sera responsable de la réexpédition du produit. Si le produit s’avère défectueux, un produit de remplacement sera envoyé gratuitement au client. Un remboursement ne sera effectué que si le produit n’est plus disponible.',
        ],
        g: [
          'Errors in shipment must be reported within 10 days of order reception by the client. We will provide a postage paid return label so the incorrect product can be returned at no charge. In order for us to take back the item, it must be unused, in its original packaging and in resalable condition. If a product is returned to us and does not meet these requirements a refund may not be accorded. Once the returned item is received the correct item will be shipped free of charge.',
          'Les erreurs d’expédition doivent être signalées dans les 10 jours suivant la réception de la commande par le client. Nous fournirons une étiquette de retour affranchie afin que le produit incorrect puisse être retourné sans frais. Afin que nous puissions reprendre l’article, il doit être inutilisé, dans son emballage d’origine et en état de revente. Si un produit nous est retourné et ne répond pas à ces exigences, un remboursement peut ne pas être accordé. Une fois l’article retourné reçu, l’article correct sera expédié gratuitement.',
        ],
        h: [
          'Items that have a cost value of over 999.99$ before taxes are considered special orders and are not returnable to Loyalty Source unless damaged in transport or errors in shipment.',
          'Les articles d’une valeur de plus de 999,99 $ avant taxes sont considérés comme des commandes spéciales et ne peuvent être retournés à Loyalty Source à moins d’être endommagés lors du transport ou d’erreurs d’expédition.',
        ],
        i: [
          'Certain large or oversized items, such as BBQs and televisions require special delivery and may have different return or claim instructions clearly indicated on the packaging of the delivered product. Clients must respect these special instructions in order to be eligible to request a return or a warranty claim.',
          'Certains articles volumineux ou surdimensionnés, tels que les barbecues et les téléviseurs, nécessitent une livraison spéciale et peuvent avoir des instructions de retour ou de réclamation différentes clairement indiquées sur l’emballage du produit livré. Les clients doivent respecter ces instructions spéciales afin de pouvoir demander un retour ou une réclamation au titre de la garantie.',
        ],
        j: [
          'Gift cards, wearable apparel, perishable goods as well as digital rewards cannot be returned once shipped out.',
          'Les cartes-cadeaux, les vêtements portables, les denrées périssables ainsi que les récompenses numériques ne peuvent pas être retournés une fois expédiés.',
        ],
        k: [
          'Unless otherwise specified in writing, restocking is not permitted.',
          'Sauf indication contraire par écrit, le réapprovisionnement n’est pas autorisé.',
        ],
        l: [
          'To request a return, report a problem or proceed to a warranty claim, please contact returns@loyaltysource.com.',
          'Pour demander un retour, signaler un problème ou procéder à une réclamation au titre de la garantie, veuillez contacter returns@loyaltysource.com.',
        ],
      },
      paragraph7list: {
        a: [
          'POs can be cancelled at anytime prior to order status changing to Picked. Once an PO’s status is set to Picked orders cannot be stopped from shipping. Only upon client reception of the order can a return request be initiated.',
          'Les bons de commande peuvent être annulés à tout moment avant que le statut de la commande ne passe à Picked. Une fois que le statut d’un bon de commande est défini sur Picked, les commandes ne peuvent pas être interrompues. Ce n’est qu’à la réception de la commande par le client qu’une demande de retour peut être initiée.',
        ],
        b: [
          'Items that have a cost value of over 999.99$ before taxes are considered special orders and are not cancellable.',
          'Les articles d’une valeur de plus de 999,99 $ avant taxes sont considérés comme des commandes spéciales et ne sont pas annulables.',
        ],
        c: [
          'Orders for items that are pending a replacement for defect or damaged in transport cannot be cancelled unless replacement item is unavailable. See sections 6.5 and 6.6.',
          'Les commandes d’articles en attente de remplacement pour défaut ou endommagés pendant le transport ne peuvent être annulées sauf si l’article de remplacement n’est pas disponible. Voir les sections 6.5 et 6.6.',
        ],
      },
      paragraph8list: {
        a: [
          'Product pricing is subject to change at any time. As stated in section 10, you will be informed accordingly.',
          'Le prix des produits est sujet à changement à tout moment. Comme indiqué dans la section 10, vous serez informé en conséquence.',
        ],
        b: [
          'If you are unable to reach your projected order volumes after a 3-month grace period following your first order or if your annual order volume is lower than the previous year, Loyalty Source reserves itself the right to review your pricing structure and payment terms.',
          'Si vous ne parvenez pas à atteindre vos volumes de commande projetés après une période de grâce de 3 mois suivant votre première commande ou si votre volume de commande annuel est inférieur à celui de l’année précédente, Loyalty Source se réserve le droit de revoir votre structure de prix et vos conditions de paiement.',
        ],
      },
      paragraph9list: {
        a: [
          'Depending on your account payment terms and the credit limit granted, invoices will be sent daily or weekly. If your credit limit is reached, all orders will stop shipping out. In such event, Loyalty Source will contact you so you may initiate a rush payment.',
          'En fonction des modalités de paiement de votre compte et de la limite de crédit accordée, les factures seront envoyées quotidiennement ou hebdomadairement. Si votre limite de crédit est atteinte, toutes les commandes cesseront d’être expédiées. Dans un tel cas, Loyalty Source vous contactera afin que vous puissiez faire un paiement spécial.',
        ],
        b: [
          'Invoices are detailed by PO, which includes: PO number, date shipped, item number, item description, quantity, price-per-unit, total price, shipping fee and taxes.',
          'Les factures sont détaillées par bon de commande, qui comprend: le numéro de bon de commande, la date d’expédition, le numéro d’article, la description de l’article, la quantité, le prix unitaire, le prix total, les frais d’expédition et les taxes.',
        ],
        c: [
          'Invoices only include products that have been shipped and may reflect partial PO.',
          'Les factures indiquent uniquement les produits qui ont été expédiés et peuvent refléter un bon de commande partiel.',
        ],
        d: [
          'Payment terms are Net 30 from invoice date.',
          'Les conditions de paiement sont de 30 jours net à compter de la date de facturation.',
        ],
        e: [
          'We accept money wires or company checks. For a 2.5% surcharge we accept Visa and MasterCard and for a 4% surcharge, we accept American Express.',
          'Nous acceptons les virements bancaires ou les chèques d’entreprise. Pour un supplément de 2,5%, nous acceptons les cartes Visa et MasterCard et pour un supplément de 4%, nous acceptons American Express.',
        ],
        f: [
          'We reserve ourselves the right to charge a 2% monthly interest fee on all late payments.',
          'Nous nous réservons le droit de facturer des frais d’intérêt mensuels de 2% sur tous les retards de paiement.',
        ],
      },
      paragraph10list: {
        a: [
          'Content updates are provided by Loyalty Source in real time via our API and with a 24-hour delay via our XML feed and on our website when logged in to your members area. It is your responsibility to ensure these content updates are reflected on your reward catalog.',
          'Les mises à jour du contenu sont fournies par Loyalty Source en temps réel via notre API et avec un délai de 24 heures via notre flux XML et sur notre site Web lorsque vous êtes connecté à votre espace membre. Il est de votre responsabilité de vous assurer que ces mises à jour de contenu sont reflétées dans votre catalogue de récompenses.',
        ],
        b: [
          'Once the product update information has been provided to you, we allow a 48-hour grace period before moving forward with the updated information. After the grace period has passed, all POs for these products will be fulfilled under the updated content without future communications.',
          'Une fois que les informations de mise à jour du produit vous ont été fournies, nous accordons un délai de grâce de 48 heures avant de continuer avec les informations mises à jour. Une fois la période de grâce écoulée, tous les bons de commande pour ces produits seront exécutés sous le contenu mis à jour sans communications futures.',
        ],
        c: [
          'If you are using a third-party service to access our content, it is your responsibility to ensure this third-party is providing you with all product updates in a timely manner.',
          'Si vous utilisez un service tiers pour accéder à notre contenu, il est de votre responsabilité de vous assurer que ce tiers vous fournit toutes les mises à jour du produit en temps opportun.',
        ],
      },
      paragraph11list: {
        a: [
          'We offer our clients bilingual customer service for all questions pertaining to products, availability, status, order escalations and any other special requests.',
          'Nous offrons à nos clients un service à la clientèle bilingue pour toutes les questions concernant les produits, la disponibilité, le statut, les escalades de commande et toute autre demande particulière.',
        ],
        b: [
          'Unless agreed to otherwise in writing, we do not allow our Client’s end users to contact us directly with their questions.',
          'Sauf convention contraire écrite, nous n’autorisons pas les participants de nos Clients à nous contacter directement pour leurs questions.',
        ],
        c: [
          'If you wish for us to handle specific questions or support directly with your end users, please speak to your account manager and he/she will establish a proper pricing structure for this additional service.',
          'Si vous souhaitez que nous traitions des questions spécifiques ou une assistance directement avec vos participants, veuillez en parler à votre directeur de compte et il établira une structure de prix appropriée pour ce service supplémentaire.',
        ],
        d: [
          'In order to facilitate order fulfillment, Loyalty Source may contact your participants. If you do not wish for us to do so, please inform your account manager in writing.',
          'Afin de faciliter l’exécution des commandes, Loyalty Source peut contacter vos participants. Si vous ne souhaitez pas que nous le fassions, veuillez en informer votre directeur de compte par écrit.',
        ],
      },
      paragraph12list: {
        a: [
          'For the duration of the Agreement with Loyalty Source and for the 12 months following the termination of this Agreement, for any reason whatsoever, directly or indirectly, in any manner whatsoever, including, but not limited to, individually or jointly with a third party and in any capacity whatsoever, you will not solicit, attempt to solicit, intervene with or attempt to encourage any current or potential employee, supplier or customer of Loyalty Source to cease rendering services or doing business to the Company.',
          'Pendant la durée du Contrat avec Loyalty Source et pendant les 12 mois suivant la résiliation du présent Contrat, pour quelque raison que ce soit, directement ou indirectement, de quelque manière que ce soit, y compris, mais sans s’y limiter, individuellement ou conjointement avec un tiers et à quelque titre que ce soit, vous ne solliciterez pas, ne tenterez pas de solliciter, d’intervenir ou de tenter d’encourager tout employé, fournisseur ou client actuel ou potentiel de Loyalty Source à cesser de rendre des services ou de faire des affaires à la Société.',
        ],
        b: [
          'During the term of this Agreement, you will not offer services or engage in any activity that competes in any way with Loyalty Source’s business, directly or indirectly, in any manner whatsoever, including, but not limited to, individually or jointly with a third party and for any reason whatsoever.',
          'Pendant la durée du présent Contrat, vous ne proposerez pas de services ou ne vous livrerez à aucune activité qui concurrence de quelque manière que ce soit l’activité de Loyalty Source, directement ou indirectement, de quelque manière que ce soit, y compris, mais sans s’y limiter, individuellement ou conjointement avec un tiers et pour quelque raison que ce soit.',
        ],
      },
      paragraph13list: {
        a: [
          'The images, videos, titles, logos, pricing, descriptions, specifications and product IDs (“the Content”) are available for you to use for the duration of this Agreement, provided that the Content be used solely to generate sales fulfilled entirely without exception by Loyalty Source. Usage of the Content for any other means is prohibited.',
          'Les images, vidéos, titres, logos, prix, descriptions, spécifications et identifiants de produits (« le contenu ») sont à votre disposition pendant la durée du présent contrat, à condition que le contenu soit utilisé uniquement pour générer des ventes réalisées entièrement sans exception par Loyalty Source. L’utilisation du contenu pour tout autre moyen est interdite.',
        ],
        b: [
          'The Content and any copies thereof, in whole or in part, and all copyright and any other proprietary rights, including moral rights, are and will remain the sole property of Loyalty Source, regardless of the use made by you of the same and in any format and are protected by Canadian copyright laws and trademark laws and international provisions. You agree not to rent, loan, license, sublicense, market, share or sell the Content or copies thereof, in whole or in part, without the written consent of Loyalty Source. This License confers no title of ownership in the Content and this license is not a sale of any rights in the Content.',
          'Le contenu et toute copie de celui-ci, en tout ou en partie, et tous les droits d’auteur et autres droits de propriété, y compris les droits moraux, sont et resteront la propriété exclusive de Loyalty Source, quelle que soit l’utilisation que vous en faites et dans n’importe quel format et sont protégés par les lois canadiennes sur les droits d’auteur, les lois sur les marques de commerce et les dispositions internationales. Vous acceptez de ne pas louer, prêter, concéder sous licence, concéder une sous-licence, commercialiser, partager ou vendre le contenu ou des copies de celui-ci, en tout ou en partie, sans le consentement écrit de Loyalty Source. Cette licence ne confère aucun titre de propriété sur le contenu et cette licence ne constitue pas une vente de droits sur le contenu.',
        ],
        c: [
          'Loyalty Source reserves the right to withdraw permission to use Content and may request you discontinue use of Content.',
          'Loyalty Source se réserve le droit de retirer l’autorisation d’utiliser le contenu et peut vous demander de cesser d’utiliser le contenu.',
        ],
      },
      paragraph14list: {
        a: [
          'As used in this Agreement, the term “Confidential Information” means any technical or business information furnished by Loyalty Source to Company in connection with the proposed business relationship, regardless of whether such information is specifically designated as confidential and regardless of whether such information is in written, oral electronic, or other form.  Such Confidential Information may include, without limitation, trade secrets, patented and non-patented technology or products, business plans, business methods, processes and procedures, know-how, images and other visual content, technical data or specifications, business or financial information, pricing, product and marketing plans, marketing techniques and customer and supplier information.',
          'Tel qu’il est utilisé dans le présent Contrat, le terme « Informations confidentielles » désigne toute information technique ou commerciale fournie par Loyalty Source à la Société dans le cadre de la relation commerciale proposée, indépendamment du fait que ces informations soient spécifiquement désignées comme confidentielles et que ces informations soient ou non en sous forme écrite, orale, électronique ou autre. Ces informations confidentielles peuvent inclure, sans s’y limiter, des secrets commerciaux, des technologies ou produits brevetés et non brevetés, des plans commerciaux, des méthodes commerciales, des processus et des procédures, un savoir-faire, des images et d’autres contenus visuels, des données ou spécifications techniques, des informations commerciales ou financières , les prix, les plans de produits et de marketing, les techniques de marketing et les informations sur les clients et les fournisseurs.',
        ],
        b: [
          'The Company receiving confidential information from Loyalty Source agrees that it shall:' +
          '\n\t (a)	Maintain all Confidential Information in strict confidence, except that the Company may disclose or permit the disclosure of any Confidential Information to its directors, officers, employees, consultants, and advisors who are obligated to maintain the confidential nature of such Confidential Information and who need to know such Confidential Information for the purposes set forth in this Agreement;' +
          '\n\t (b)	Use all Confidential Information solely for the purposes set forth in this Agreement; ' +
          '\n\t (c)	Allow its directors, officers, employees, consultants, and advisors to reproduce the Confidential Information only to the extent necessary to effect the purposes set forth in this Agreement, with all such reproductions being considered Confidential Information.',
          'La Société recevant des informations confidentielles de Loyalty Source s’engage à:' +
          '\n\t (a)	Conserver toutes les informations confidentielles dans la plus stricte confidentialité, sauf si la société peut divulguer ou permettre la divulgation de toute information confidentielle à ses administrateurs, dirigeants, employés, consultants et conseillers qui sont tenus de maintenir la nature confidentielle de ces informations confidentielles et qui ont besoin de connaître ces informations confidentielles aux fins énoncées dans le présent accord.' +
          '\n\t (b)	Utiliser toutes les informations confidentielles uniquement aux fins énoncées dans le présent accord.' +
          '\n\t (c)	Autoriser ses administrateurs, dirigeants, employés, consultants et conseillers à reproduire les informations confidentielles uniquement dans la mesure nécessaire pour affecter les objectifs énoncés dans le présent accord, toutes ces reproductions étant considérées comme des informations confidentielles.',
        ],
        c: [
          'The Company acknowledges that Loyalty Source claims ownership of the Confidential Information disclosed and all patent, copyright, trademark, trade secret, and other intellectual property rights in, or arising from, such Confidential Information.  No option, license, or conveyance or such rights to the Company is granted or implied under this Agreement.  If any such rights are to be granted to the Company, such grant shall be expressly set forth in a separate written instrument.',
          'La Société reconnaît que Loyalty Source revendique la propriété des Informations confidentielles divulguées et de tous les brevets, droits d’auteur, marques de commerce, secrets commerciaux et autres droits de propriété intellectuelle sur ou découlant de ces Informations confidentielles. Aucune option, licence ou transfert ou de tels droits à la Société ne sont accordés ou implicites en vertu du présent Contrat. Si de tels droits doivent être accordés à la Société, cette subvention sera expressément énoncée dans un instrument écrit distinct.',
        ],
        d: [
          'The Company acknowledges that Loyalty Source makes no representations and gives no warranties of any kind regarding the Confidential Information disclosed to Company and shall have no liability with respect to any such Confidential Information under this Agreement.',
          'La Société reconnaît que Loyalty Source ne fait aucune déclaration et ne donne aucune garantie de quelque nature que ce soit concernant les informations confidentielles divulguées à la société et n’assumera aucune responsabilité à l’égard de ces informations confidentielles en vertu du présent accord.',
        ],
        e: [
          'In the event of a termination of this Agreement, the obligations set forth in paragraph 14 shall remain in effect with respect to Confidential Information disclosed pursuant to this Agreement.',
          'En cas de résiliation du présent Contrat, les obligations énoncées au paragraphe 14 resteront en vigueur en ce qui concerne les Informations Confidentielles divulguées conformément au présent Contrat.',
        ],
        f: [
          'The Company agrees that any breach of its obligations under this Agreement will cause irreparable harm to Loyalty Source; therefore, Loyalty Source shall have, in addition to any remedies available at law, the right to obtain equitable relief to enforce this Agreement.  This Agreement shall be governed by the laws of the Province of Quebec, without regard to its conflict of law rules.',
          'La Société convient que tout manquement à ses obligations en vertu du présent Contrat causera un préjudice irréparable à Loyalty Source; Par conséquent, Loyalty Source aura, en plus de tout recours disponible en droit, le droit d’obtenir une réparation équitable pour faire appliquer le présent Contrat. La présente entente est régie par les lois de la province de Québec, sans égard à ses règles de conflit de lois.',
        ],
      },
      paragraph15list: {
        a: [
          'The initial term of this Agreement shall be three (3) years from the Effective Date (the “Initial Term”). This Agreement shall be automatically renewed after the Initial Term or any of its successive terms for additional one (1)-year terms (each a “Renewal Term”) unless either Party notifies the other in writing of its intention not to renew the Agreement at sixty (60) days prior to the expiration of the initial three (3) year term or any subsequent Term.  Taken together, the Initial Term and all Renewal Terms shall be referred to as the “Term.”',
          'La durée initiale de cet accord sera de trois (3) ans à compter de la date d’entrée en vigueur (la « durée initiale »). Le présent accord sera automatiquement renouvelé après la durée initiale ou l’une de ses conditions successives pour des termes supplémentaires d’un (1) an (chacun une « durée de renouvellement ») à moins que l’une des parties ne notifie à l’autre par écrit son intention de ne pas renouveler l’accord à soixante (60) jours avant l’expiration du terme initial de trois (3) ans ou de tout terme subséquent. Pris ensemble, la durée initiale et toutes les conditions de renouvellement seront appelées la « durée ».',
        ],
      },
      paragraph16list: {
        a: [
          'Both Parties may terminate this Agreement at anytime for convenience reasons sixty (60) days after notifying the other Party by form of written notice and proof of reception.',
          'Les deux parties peuvent résilier le présent accord à tout moment pour des raisons de commodité soixante (60) jours après en avoir informé l’autre partie par notification écrite et preuve de réception.',
        ],
        b: [
          'Upon Termination of this Agreement by either Party and for a during of sixty (60) days following the Termination date, Loyalty Source agrees to uphold to the best of its capacity its quality of service on all remaining unfulfilled orders.',
          'À la résiliation du présent Contrat par l’une ou l’autre des Parties et pendant une période de soixante (60) jours suivant la date de Résiliation, Loyalty Source s’engage à maintenir au mieux de sa capacité sa qualité de service sur toutes les commandes restantes non exécutées.',
        ],
        c: [
          'Upon Termination of this Agreement by the Company, you agree to purchase, at the established fulfilment price, all the inventory pre-purchased by Loyalty Source to fulfill the forecasted sales of the Company for the following sixty (60) day period following the Termination notice reception date.',
          'À la résiliation du présent Contrat par la Société, vous acceptez d’acheter, au prix d’exécution établi, tout l’inventaire préacheté par Loyalty Source pour réaliser les ventes prévues de la Société pour la période de soixante (60) jours suivant la Date de réception de l’avis de résiliation.',
        ],
        d: [
          'Upon Termination of this Agreement by either Party, the Company agrees to pay all open and pending invoices following the Invoicing terms.',
          'À la résiliation du présent Contrat par l’une ou l’autre des Parties, la Société s’engage à payer toutes les factures ouvertes et en attente conformément aux conditions de facturation.',
        ],
      },
      paragraph17list: {
        a: [
          'This Vendor Agreement supersedes any other contracts, performance clauses or agreement between Loyalty Source and Company, unless otherwise agreed upon in writing by a Loyalty Source authorised representative.',
          'Ce contrat de fournisseur remplace tout autre contrat, clause de performance ou accord entre Loyalty Source et la société, sauf accord contraire par écrit d’un représentant autorisé de la source de fidélité.',
        ],
        b: [
          'The parties have required that this Agreement be drawn up in the English language. Les parties aux présentes ont exigé que le présent Contrat soit rédigé en langue anglaise.',
          'Les parties ont exigé que le présent accord soit rédigé en langue française.',
        ],
        c: [
          'This Agreement and the relationships of the Parties in connection with the subject matter of this Agreement shall be governed by, construed, and determined in accordance with the laws of the Province of Quebec applicable therein without regard to conflicts of law principles thereof.',
          'La présente entente et les relations des parties en rapport avec l’objet de la présente entente seront régies, interprétées et déterminées conformément aux lois de la province de Québec qui y sont applicables sans égard aux conflits de principes juridiques de celle-ci.',
        ],
      },
    },
    accept: {
      acknowledge: [
        'By signing this Agreement Company acknowledges and agrees to the terms and conditions set forth in this Agreement.',
        'En signant cet accord, la société reconnaît et accepte les termes et conditions énoncés dans le présent accord.',
      ],
      pleaseEnterNameAndAgree: [
        'Please enter your name and sign to accept the agreement.',
        'Veuillez inscrire votre nom et signer pour accepter l’entente.'
      ],
      enterYourName: [
        'Type your name here (duly authorised representative)',
        'Tapez votre nom ici (représentant dûment autorisé)',
      ],
      agreeToTerms: [
        'Click this checkbox to accept the Agreement',
        'Cochez cette case pour accepter l’entente',
      ],
      confirmAndSubmitAgreement: [
        'Confirm and submit your agreement',
        'Confirmer et soumettre votre acceptation',
      ],
      signature: [
        'Draw your signature below:',
        'Dessinez votre signature ci-dessous:'
      ]
    }
  },
  //End Vendor Agreement page

  //Vendor Agreement Success
  vendorAgreementSuccess: {
    title: ['Thank you!', 'Merci!'],
    intro: {
      titleMsg1: [
        'Thank you for submitting your agreement.',
        'Merci d’avoir soumis votre accord.',
      ],
      titleMsg2: [
        'You will receive a copy of the signed Agreement in the next 24 hours.',
        'Vous recevrez une copie de l’accord signé dans les prochaines 24 heures.',
      ],
    },
  },
}

export default globalLexicon;
